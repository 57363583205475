import React, { useCallback } from "react";

import DashboardTopRow from "../../../../components/blocks/dashboardTopRow";
import Header from "../../../../components/blocks/header";

import LeadershipBoard from "../../../../components/blocks/leadershipBoard";

import MarketingTop from "../../../../components/blocks/marketingTop";
import CustomerDashboard from "../customerDashboard";
import HeaderContainer from "../../../../components/blocks/HeaderContainer";
import { Focus } from "./components/focus";

import { useGlobalContext } from "../../../../contexts/globalContext";
import ActivityFeed from "../activities/activityFeed";
import ChurnInfoCard from "../churn/ChurnInfoCard";
import { useGetChurnOrderCount } from "../../../../hooks/useExodus";

import { getPrivileges } from "../../../../services/authService";
// import useDateRange from "../../../../hooks/useDateRange";
// import { formatDateToUnix } from "../churn/Churn";
import useGetDateRange from "../../../../hooks/dates/use-get-date-range";

const Home = () => {
  const privileges = getPrivileges();
  const { user } = useGlobalContext();

  const getUserId = useCallback(
    (user: any) => {
      if (!privileges.createAgent) {
        return user;
      }
      return "";
    },
    [privileges.createAgent]
  );

  // const [selectedType] = useState<{
  //   full: boolean;
  //   partial: boolean;
  //   risk: boolean;
  //   exit: boolean;
  // }>({
  //   full: true,
  //   partial: true,
  //   risk: true,
  //   exit: true,
  // });

  // const [startFull, endFull] = useDateRange("full");

  const { getSeconds } = useGetDateRange("full");

  const newFullRange = getSeconds().readable;

  const { getSeconds: riskMilliseconds } = useGetDateRange("risk");
  const newRiskRange = riskMilliseconds().readable;

  const { getSeconds: exitMilliseconds } = useGetDateRange("exit");
  const newExitRange = exitMilliseconds().readable;

  // const [full, setFull] = useState("");
  // const [partial, setPartial] = useState("");
  // const [risk, setRisk] = useState("");
  // const [exit, setExit] = useState("");

  const filters = {
    user_id: getUserId(user?.id) as string,
    status: "churn",
  };

  // const { churnCount: fullCount } = useGetChurnOrderCount({
  //   ...filters,

  //   // date_range: full,
  //   date_range: `${startFull.getTime()},${endFull.getTime()}`,
  //   // date_range: `${formatDateToUnix(startFull)},${formatDateToUnix(endFull)}`,
  // });

  const { churnCount: fullCount2 } = useGetChurnOrderCount({
    ...filters,
    date_range: newFullRange,
  });

  const { churnCount: partialCount } = useGetChurnOrderCount({
    ...filters,
    churnstatus: "not_null",
    date_range: newFullRange,
  });

  // const { churnCount: riskCount } = useGetChurnOrderCount({
  //   ...filters,
  //   date_range: risk,
  // });

  const { churnCount: riskCount2 } = useGetChurnOrderCount({
    ...filters,
    date_range: newRiskRange,
  });

  // const { churnCount: existCount } = useGetChurnOrderCount({
  //   ...filters,
  //   date_range: exit,
  // });

  const { churnCount: existCount2 } = useGetChurnOrderCount({
    ...filters,
    date_range: newExitRange,
  });

  // useEffect(() => {
  //   if (selectedType) {
  //     const today = new Date();
  //     let start = new Date();
  //     let end = new Date();

  //     const riskStart = new Date(today.getFullYear(), 6, 1); // July 1, current year
  //     const riskEnd = new Date(today.getFullYear(), 8, 30); // September 30, current year

  //     if (selectedType.full) {
  //       // start.setFullYear(today.getFullYear() - 1); // Full is from a year ago

  //       start = new Date(today.getFullYear() - 1, 8, 30); // September 30, previous year
  //       // end = new Date(today.getFullYear(), 6, 1);
  //       // const fullDateRange = `${Math.floor(
  //       //   start.getTime() / 1000
  //       // )},${Math.floor(end.getTime() / 1000)}`;
  //       const fullDateRange = `${start.getTime()},${end.getTime()}`;
  //       setFull(fullDateRange);
  //     }

  //     if (selectedType.partial) {
  //       // start.setFullYear(today.getFullYear() - 1); // Partial is from a year ago

  //       start = new Date(today.getFullYear() - 1, 8, 30); // September 30, previous year
  //       end = new Date(today.getFullYear(), 6, 1);
  //       const partialDateRange = `${Math.floor(
  //         start.getTime() / 1000
  //       )},${Math.floor(end.getTime() / 1000)}`;
  //       setPartial(partialDateRange);
  //     }

  //     if (selectedType.risk) {
  //       let startRisk = riskStart; // Clone today's date

  //       startRisk.setMonth(today.getMonth() - 3);

  //       start = riskStart;
  //       end = riskEnd;
  //       // Handle year rollover if the month subtraction goes to the previous year
  //       // if (startRisk.getMonth() > today.getMonth()) {
  //       //   startRisk.setFullYear(today.getFullYear() - 1);
  //       // }

  //       const riskDateRange = `${Math.floor(
  //         start.getTime() / 1000
  //       )},${Math.floor(end.getTime() / 1000)}`;
  //       setRisk(riskDateRange);
  //     }

  //     if (selectedType.exit) {
  //       // start.setFullYear(today.getFullYear() - 2); // Exit is from 2 years ago

  //       start = new Date(today.getFullYear() - 3, 8, 30); // September 30, two years ago
  //       end = new Date(today.getFullYear() - 2, 6, 1);

  //       const exitDateRange = `${Math.floor(
  //         start.getTime() / 1000
  //       )},${Math.floor(end.getTime() / 1000)}`;
  //       setExit(exitDateRange);
  //     }
  //   }
  // }, [selectedType]);

  return user?.position === "marketing" ? (
    <React.Fragment>
      <Header title={`Hello, ${user.username}`} />
      <MarketingTop />
    </React.Fragment>
  ) : user?.position === "customer_success" ? (
    <React.Fragment>
      <Header title={`Hello, ${user.username}`} />
      <CustomerDashboard />
    </React.Fragment>
  ) : user?.position === "sales_rep" ? (
    <React.Fragment>
      <Header title={`Hello, ${user?.username}`} />
      <HeaderContainer />
      <div className="flex flex-col justify-center items-center">
        <Focus />
      </div>
    </React.Fragment>
  ) : (
    <React.Fragment>
      <Header title={`Hello, ${user?.username}`} />
      <div className="px-3">
        <DashboardTopRow />
      </div>
      <div className="flex flex-col-reverse lg:flex-row gap-3">
        <div className="w-full">
          <ActivityFeed pageSize={3} />
        </div>
        <div className="mt-3 lg:hidden">
          <ChurnInfoCard
            full={fullCount2}
            partial={partialCount}
            risk={riskCount2}
            exit={existCount2}
          />
        </div>
        <div className="bottom-row-breakdown">
          <div className="bottom-row-breakdown-right">
            <div className="bottom-row-breakdown-right-inner">
              <LeadershipBoard />
            </div>
            <div className="mt-3 hidden lg:block">
              <ChurnInfoCard
                full={fullCount2}
                partial={partialCount}
                risk={riskCount2}
                exit={existCount2}
              />
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Home;
