import { useState, useEffect } from "react";

export type DateFormat =
  | "ordinal-short" // 1st Dec, 2024
  | "ordinal-long" // 1st December, 2024
  | "numeric" // 01/12/2024
  | "time-12" // 12 noon, 1 PM, 10 AM
  | "datetime-12" // 1st Dec, 2024 12:00 PM
  | "datetime-full" // 1st December, 2024 12:00 PM
  | "timestamp-12hour"; // 2023-08-23 11:23:09am

const useDateString = (
  date: Date | string | number,
  format: DateFormat
): string => {
  const [formattedDate, setFormattedDate] = useState<string>("");

  const getOrdinalSuffix = (day: number): string => {
    if (day > 3 && day < 21) return "th";
    switch (day % 10) {
      case 1:
        return "st";
      case 2:
        return "nd";
      case 3:
        return "rd";
      default:
        return "th";
    }
  };

  const formatTime12Hour = (hours: number, minutes: number): string => {
    if (hours === 12 && minutes === 0) return "12 noon";
    if (hours === 0 && minutes === 0) return "12 midnight";

    const period = hours >= 12 ? "PM" : "AM";
    const hour12 = hours % 12 || 12;

    return `${hour12}:${minutes.toString().padStart(2, "0")} ${period}`;
  };

  const formatTimestamp12Hour = (dateObj: Date): string => {
    const year = dateObj.getFullYear();
    const month = (dateObj.getMonth() + 1).toString().padStart(2, "0");
    const day = dateObj.getDate().toString().padStart(2, "0");
    const hours = dateObj.getHours();
    const minutes = dateObj.getMinutes().toString().padStart(2, "0");
    const seconds = dateObj.getSeconds().toString().padStart(2, "0");
    const period = hours >= 12 ? "pm" : "am";
    const hour12 = (hours % 12 || 12).toString().padStart(2, "0");

    return `${year}-${month}-${day} ${hour12}:${minutes}:${seconds}${period}`;
  };

  useEffect(() => {
    try {
      let dateObj: Date;

      if (date instanceof Date) {
        dateObj = date;
      } else if (typeof date === "number" || !isNaN(Number(date))) {
        const timestamp = Number(date) * 1000;
        dateObj = new Date(timestamp);
      } else {
        dateObj = new Date(date);
      }

      if (isNaN(dateObj.getTime())) {
        setFormattedDate("Invalid Date");
        return;
      }

      const day = dateObj.getDate();
      const month = dateObj.getMonth();
      const year = dateObj.getFullYear();
      const hours = dateObj.getHours();
      const minutes = dateObj.getMinutes();

      const months = {
        short: [
          "Jan",
          "Feb",
          "Mar",
          "Apr",
          "May",
          "Jun",
          "Jul",
          "Aug",
          "Sep",
          "Oct",
          "Nov",
          "Dec",
        ],
        long: [
          "January",
          "February",
          "March",
          "April",
          "May",
          "June",
          "July",
          "August",
          "September",
          "October",
          "November",
          "December",
        ],
      };

      let result = "";

      switch (format) {
        case "ordinal-short":
          result = `${day}${getOrdinalSuffix(day)} ${
            months.short[month]
          }, ${year}`;
          break;

        case "ordinal-long":
          result = `${day}${getOrdinalSuffix(day)} ${
            months.long[month]
          }, ${year}`;
          break;

        case "numeric":
          result = `${(month + 1).toString().padStart(2, "0")}/${day
            .toString()
            .padStart(2, "0")}/${year}`;
          break;

        case "time-12":
          result = formatTime12Hour(hours, minutes);
          break;

        case "datetime-12":
          result = `${day}${getOrdinalSuffix(day)} ${
            months.short[month]
          }, ${year} ${formatTime12Hour(hours, minutes)}`;
          break;

        case "datetime-full":
          result = `${day}${getOrdinalSuffix(day)} ${
            months.long[month]
          }, ${year} ${formatTime12Hour(hours, minutes)}`;
          break;

        case "timestamp-12hour":
          result = formatTimestamp12Hour(dateObj);
          break;

        default:
          result = dateObj.toString();
      }

      setFormattedDate(result);
    } catch (error) {
      setFormattedDate("Invalid Date");
    }
  }, [date, format]);

  return formattedDate;
};

export default useDateString;

// Updated DateCell component
