import React from "react";
import _ from "lodash";
import DateCell from "../DateCell";
import { JSX } from "react/jsx-runtime";

type Column = {
  label?: string;
  path?: string;
  key?: string;
  content?: (item: any) => JSX.Element | string | number;
};

type DataItem = {
  created: string | number | JSX.Element;
  date: string | number | JSX.Element;
  releasedate: string | number;
  [key: string]: any; // Allow additional properties
};

type TableBodyProps = {
  data: DataItem[];
  columns: Column[];
};

const TableBody: React.FC<TableBodyProps> = ({ data, columns }) => {
  const formatDate = (dateString: string | number): JSX.Element => {
    return <DateCell dateString={dateString} format="ordinal-short" />;
  };

  const modifiedData = data.map((item) => {
    const modifiedItem = { ...item };
    if (item.created) {
      modifiedItem.created = formatDate(item.created as string);
    }
    if (item.date) {
      modifiedItem.date = formatDate(item.date as string);
    }
    if (item.releasedate) {
      modifiedItem.date = formatDate(item.releasedate);
    }
    return modifiedItem;
  });

  const renderCell = (item: DataItem, column: Column) => {
    if (column.content) return column.content(item);
    return _.get(item, column.path as string);
  };

  const createKey = (column: Column): string => {
    return _.uniqueId() + (column.path || column.key || "");
  };

  return (
    <tbody>
      {modifiedData.map((item, index) => (
        <tr key={index.toString()}>
          {columns.map((column) => (
            <td data-label={column.label} key={createKey(column)}>
              {renderCell(item, column) ?? "N/A"}
            </td>
          ))}
        </tr>
      ))}
    </tbody>
  );
};

export default TableBody;
