import React, { useState, useEffect, useMemo } from "react";
import { IoCloudUploadOutline } from "react-icons/io5";
import { Button } from "../../../../@/components/ui/button";

import Tabs from "./tabs";
import { useNavigate } from "react-router-dom";

import { useGlobalContext } from "../../../../contexts/globalContext";

import * as Dialog from "@radix-ui/react-dialog";
import toastService from "../../../../services/toastService";
import { useDispatch } from "react-redux";
import { addHospitalId } from "../../../../redux/slices/Cart";
import { useUploadQuotation } from "../../../../hooks/purchasingOrder/usePurchaseOrder";
import ProgressLoader from "../../../../components/common/progressLoader";
import {
  useGetOrders,
  useGetOrderHistory,
} from "../../../../hooks/purchasingOrder/usePurchaseOrder";
import TransitionsModal from "../../../../components/modals/mod/CustomModal";

import { SyncLoader } from "react-spinners";
import ReactSelect from "../../../../components/common/ReactSelect";
import { useGetAgentAdminHospitals } from "../../../../hooks/agent/useAgent";
import PaymentMethods from "./PaymentMethod";

export default function Order() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { user } = useGlobalContext();
  const [paymentType, setPaymentType] = useState("");
  const [isContinue, setIsContinue] = useState(false);
  const [createPo, setCreatePo] = useState(false);
  const [progress, setProgress] = useState(0);
  const [selectedHospital, setSelectedHospital] = useState<string>("");
  const [isUpload, setIsUpload] = useState(false);
  const [poUpload, setPoUpload] = useState<File | null>(null);
  const [period, setPeriod] = useState<string>("");
  const [isOpen, setIsOpen] = useState(false);
  const [openOrderDetails, setOpenOrderDetails] = useState(false);
  const [orderType, setOrderType] = useState("active");
  const [orderDetail, setOrderDetail] = useState<{
    order_by: string;
    id: string;
    hospital_name: string;
    address: string;
    type: string;
    dispatch: string;
    product: string;
    urgency: string;
    qty: number | string;
    usage: string;
    order_state: string;
    payment: string;
  } | null>(null);

  const handleProgressChange = (progress: any) => {
    setProgress(progress);
  };
  const { mutateUploadQuotation, isPending } =
    useUploadQuotation(handleProgressChange);

  const { hospitals } = useGetAgentAdminHospitals(
    "",
    5000,
    "",
    "",
    user?.id as string
  );

  const { orders, isPending: isLoadingOrders } = useGetOrders(
    user?.id as string
  );

  // console.log(orders);

  const hospitalsWithRefId = useMemo(
    () =>
      hospitals?.filter(
        (hospital: { ref_id: string }) =>
          hospital.ref_id && hospital.ref_id !== "0"
      ) || [],
    [hospitals]
  );

  useEffect(() => {
    if (hospitalsWithRefId.length > 0) {
      setSelectedHospital(hospitalsWithRefId[0].ref_id);
      dispatch(addHospitalId(hospitalsWithRefId[0].ref_id));
    }
  }, [dispatch, hospitalsWithRefId]);

  const handleCreatePO = () => {
    navigate("/purchasing-orders/category");
  };

  const completedOrder = [
    {
      label: "Oxygen",
      title: "Oxygen Orders",
      items: orders
        ? orders?.oxygen?.message
            .map(
              (item: {
                id: string;
                order_by: string;
                payment: string;
                qty: string;
                tym: string;
                urgency: string;
                product: string;
                dispatch: string;
                unitprice: string;
                paymentdetails: string;
                schedule_date: string;
                schedule_time: string;
                order_state: string;
                complete_tym: string;
                created_at: string;
                hospital_name: string;
                cylinder: string;
              }) => ({
                id: item.id,
                order_by: item.order_by,
                payment: item.payment,
                qty: item.qty,
                tym: item.tym,
                urgency: item.urgency,
                product: "oxygen",
                dispatch: item.dispatch,
                unitprice: item.unitprice,
                paymentdetails: item.paymentdetails,
                schedule_date: item.schedule_date,
                schedule_time: item.schedule_time,
                order_state: item.order_state,
                complete_tym: item.complete_tym,
                created_at: item.created_at,
                hospital_name: item.hospital_name,
                type: item.cylinder,
              })
            )
            .filter((item: { id: null }) => item.id !== null)
        : [],
    },
    {
      label: "Blood",
      title: "Blood Orders",
      items: orders
        ? orders?.blood?.message
            .map(
              (item: {
                Request_Type: any;
                blood: any;
                order_id: any;
                id: string;
                order_by: string;
                payment_type: string;
                order_amt: string;
                tym: string;
                urgency: string;
                product: string;
                dispatch: string;
                unitprice: string;
                paymentdetails: string;
                schedule_date: string;
                schedule_time: string;
                order_state: string;
                complete_tym: string;
                created_at: string;
                hospital_name: string;
                b_type: string;
                usage_info: string;
              }) => ({
                id: item.order_id,
                order_by: item.order_by,
                payment: item.payment_type,
                qty: item.order_amt,
                tym: item.tym,
                urgency: item.Request_Type,
                product: "blood",
                type: item.b_type,
                usage: item.usage_info,
                dispatch: item.dispatch,
                unitprice: item.unitprice,
                paymentdetails: item.paymentdetails,
                schedule_date: item.schedule_date,
                schedule_time: item.schedule_time,
                order_state: item.order_state,
                complete_tym: item.complete_tym,
                created_at: item.created_at,
                hospital_name: item.hospital_name,
              })
            )
            .filter((item: { id: null }) => item.id !== null)
        : [],
    },
    {
      label: "Quip",
      title: "Quip Orders",
      items: orders
        ? orders?.quip?.message
            .map(
              (item: {
                id: string;
                order_by: string;
                payment: string;
                qty: string;
                tym: string;
                urgency: string;
                product: string;
                dispatch: string;
                unitprice: string;
                paymentdetails: string;
                schedule_date: string;
                schedule_time: string;
                order_state: string;
                complete_tym: string;
                created_at: string;
                hospital_name: string;
                b_type: string;
              }) => ({
                id: item.id,
                order_by: item.order_by,
                payment: item.payment,
                qty: item.qty,
                tym: item.tym,
                urgency: item.urgency,
                product: item.product,
                dispatch: item.dispatch,
                unitprice: item.unitprice,
                paymentdetails: item.paymentdetails,
                schedule_date: item.schedule_date,
                schedule_time: item.schedule_time,
                order_state: item.order_state,
                complete_tym: item.complete_tym,
                created_at: item.created_at,
                hospital_name: item.hospital_name,
                type: item.b_type,
              })
            )
            .filter((item: { id: null }) => item.id !== null)
        : [],
    },
    {
      label: "StockBank",
      title: "StockBank Orders",
      items: orders
        ? orders?.stockbank?.message
            .map(
              (item: {
                id: string;
                order_by: string;
                payment: string;
                qty: string;
                tym: string;
                urgency: string;
                product: string;
                dispatch: string;
                unitprice: string;
                paymentdetails: string;
                schedule_date: string;
                schedule_time: string;
                order_state: string;
                complete_tym: string;
                created_at: string;
                hospital_name: string;
                b_type: string;
              }) => ({
                id: item.id,
                order_by: item.order_by,
                payment: item.payment,
                qty: item.qty,
                tym: item.tym,
                urgency: item.urgency,
                product: item.product,
                dispatch: item.dispatch,
                unitprice: item.unitprice,
                paymentdetails: item.paymentdetails,
                schedule_date: item.schedule_date,
                schedule_time: item.schedule_time,
                order_state: item.order_state,
                complete_tym: item.complete_tym,
                created_at: item.created_at,
                hospital_name: item.hospital_name,
                type: item.b_type,
              })
            )
            .filter((item: { id: null }) => item.id !== null)
        : [],
    },
  ];

  function handleHospitalRefId(value: string): void {
    setSelectedHospital(value);
    dispatch(addHospitalId(value ?? selectedHospital));
  }

  const uploadPO = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      const file = e.target.files[0];
      const maxSize = 1024 * 1024 * 10;
      if (file.size <= maxSize) {
        setPoUpload(file);
      } else {
        console.log("error");
        toastService.error(
          "File size exceeds the limit, please choose image less than 10MB"
        );
      }
    }
  };

  const handleSubmitUpload = (period: string) => {
    setPeriod(period);
    const formData = new FormData();
    formData.append("file", poUpload as Blob);
    formData.append("quipOrStock", "stock");
    formData.append("hospitalid", selectedHospital);
    formData.append("numberOfDays", period);
    formData.append("payment_method", paymentType);

    mutateUploadQuotation(formData, {
      onSuccess: (data) => {
        toastService.success(data?.data?.message);
        setProgress(0);
        setPoUpload(null);
        setIsOpen(false);
        setPaymentType("");
        setIsContinue(false);
        setPeriod("");
      },
      onError: (err) => {
        console.log(err);
      },
    });
  };

  const handleCloseModal = () => {
    setIsOpen(false);
    setPoUpload(null);
    setPaymentType("");
    setIsContinue(false);
  };

  const handleCloseOrderDetailModal = () => {
    setOpenOrderDetails(false);
    setOrderDetail(null);
  };

  const handleOrderType = (type: string) => {
    setOrderType(type);
  };

  const handleViewOrder = (item: any) => {
    // console.log(item);
    setOrderDetail(item);
    setOpenOrderDetails(true);
  };

  const { hospitalInfo } = useGetOrderHistory(
    orderDetail?.id as string,
    orderDetail?.product as string
  );

  const [hospital] = Array.isArray(hospitalInfo) ? hospitalInfo : [];

  const handleTrackOrder = (
    user_id: string,
    order_id: string,
    order_type: string
  ) => {
    navigate(`/order/track/${user_id}/${order_id}/${order_type}`);
  };

  function handlePaymentType(paymentType: any): void {
    setPaymentType(paymentType);
  }

  return (
    <div>
      <ProgressLoader progress={progress} />
      <>
        <div className="flex justify-end items-center">
          <div className="items-center gap-x-3 mt-6 md:mt-0 flex">
            <Button
              className="flex py-2 px-4 border font-medium md:text-sm rounded-md bg-white text-gray-700 items-center justify-center gap-2"
              onClick={() => setIsUpload(true)}
            >
              <IoCloudUploadOutline className="w-5 h-5" />
              Upload PO{" "}
              <span className="hidden lg:block"> (Purchase Order)</span>
            </Button>
            <Button
              onClick={() => {
                setCreatePo(true);
              }}
              className="flex items-center text-white px-4 py-2 rounded-md shadow-sm bg-blue-600 hover:bg-blue-700 active:bg-blue-800 cursor-pointer"
            >
              Create PO{" "}
              <span className="hidden lg:block"> (Purchase Order)</span>
            </Button>
          </div>
        </div>
      </>
      <Dialog.Root open={isUpload} onOpenChange={setIsUpload}>
        <Dialog.Portal>
          <Dialog.Overlay className="data-[state=open]:animate-overlayShow fixed inset-0 w-full h-full bg-black opacity-40" />
          <Dialog.Content
            aria-describedby={undefined}
            className="fixed top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%] w-full max-w-lg mx-auto px-4"
          >
            <div className="bg-white rounded-md shadow-lg px-4 py-6">
              <div className="flex items-center justify-end">
                <Dialog.Close
                  className="p-2 text-gray-400 rounded-md hover:bg-gray-100"
                  onClick={() => {
                    setIsUpload(false);
                    setPoUpload(null);
                  }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="w-5 h-5 mx-auto"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                  >
                    <path
                      fillRule="evenodd"
                      d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                      clipRule="evenodd"
                    />
                  </svg>
                </Dialog.Close>
              </div>
              <div className="max-w-sm mx-auto space-y-3 text-center ">
                <Dialog.Title className="text-lg font-medium text-gray-800 ">
                  Select Hospital
                </Dialog.Title>
                <ReactSelect
                  defaultValue={selectedHospital}
                  placeholder="Select a Hospital"
                  options={hospitalsWithRefId?.map(
                    (hospital: { ref_id: string; name: string | number }) => ({
                      value: hospital.ref_id,
                      label: hospital.name,
                    })
                  )}
                  onChange={handleHospitalRefId}
                />

                <>
                  <label
                    htmlFor="uploadpo"
                    className="flex bg-white items-center justify-center gap-x-2 px-4 py-2 text-center text-gray-700 duration-150 font-medium rounded-lg border hover:bg-gray-50 active:bg-gray-100 md:text-sm cursor-pointer"
                  >
                    <IoCloudUploadOutline className="w-5 h-5" />
                    Upload PO (Purchase Order)
                    <input
                      type="file"
                      id="uploadpo"
                      accept=".jpeg, .png, .jpg, .pdf"
                      className="hidden"
                      onChange={uploadPO}
                    />
                  </label>
                  {/* uploaded file */}
                  {poUpload?.name && (
                    <div className="flex items-center justify-center gap-x-2">
                      <span className="text-gray-700">File uploaded:</span>
                      <span className="text-gray-700">{poUpload?.name}</span>
                    </div>
                  )}
                  <button
                    onClick={() => {
                      if (selectedHospital === "") {
                        toastService.error("Please select a hospital");
                        return;
                      }
                      if (!poUpload) {
                        toastService.error("Please upload a file");
                        return;
                      }
                      setIsOpen(true);
                      setIsUpload(false);
                    }}
                    className=" w-full mt-3 py-3 px-4 font-medium text-sm text-center text-white bg-indigo-600 hover:bg-indigo-500 active:bg-indigo-700 rounded-lg ring-offset-2 ring-indigo-600 focus:ring-2"
                  >
                    Continue
                  </button>
                </>
              </div>
            </div>
          </Dialog.Content>
        </Dialog.Portal>
      </Dialog.Root>

      <Dialog.Root open={createPo} onOpenChange={setCreatePo}>
        <Dialog.Portal>
          <Dialog.Overlay className="data-[state=open]:animate-overlayShow fixed inset-0 w-full h-full bg-black opacity-40" />
          <Dialog.Content
            aria-describedby={undefined}
            className="fixed top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%] w-full max-w-lg mx-auto px-4"
          >
            <div className="bg-white rounded-md shadow-lg px-4 py-6">
              <div className="flex items-center justify-end">
                <Dialog.Close
                  className="p-2 text-gray-400 rounded-md hover:bg-gray-100"
                  onClick={() => {
                    setCreatePo(false);
                  }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="w-5 h-5 mx-auto"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                  >
                    <path
                      fillRule="evenodd"
                      d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                      clipRule="evenodd"
                    />
                  </svg>
                </Dialog.Close>
              </div>
              <div className="max-w-sm mx-auto space-y-3 text-center ">
                <Dialog.Title className="text-lg font-medium text-gray-800 ">
                  Select Hospital
                </Dialog.Title>
                <ReactSelect
                  placeholder="Select a Hospital"
                  options={hospitalsWithRefId?.map(
                    (hospital: { ref_id: string; name: string | number }) => ({
                      value: hospital.ref_id,
                      label: hospital.name,
                    })
                  )}
                  onChange={handleHospitalRefId}
                />

                {/* <Select
                  onValueChange={handleHospitalRefId}
                  defaultValue={selectedHospital}
                >
                  <SelectTrigger>
                    <SelectValue placeholder="Select a hospital" />
                  </SelectTrigger>
                  <SelectContent position="item-aligned">
                    {hospitalsWithRefId?.map(
                      (hospital: { ref_id: string; name: string | number }) => (
                        <SelectItem
                          key={hospital.ref_id}
                          value={hospital.ref_id}
                        >
                          {hospital.name}
                        </SelectItem>
                      )
                    )}
                  </SelectContent>
                </Select> */}

                <Dialog.Close asChild>
                  <button
                    onClick={handleCreatePO}
                    className=" w-full mt-3 py-3 px-4 font-medium text-sm text-center text-white bg-indigo-600 hover:bg-indigo-500 active:bg-indigo-700 rounded-lg ring-offset-2 ring-indigo-600 focus:ring-2"
                  >
                    Continue
                  </button>
                </Dialog.Close>
              </div>
            </div>
          </Dialog.Content>
        </Dialog.Portal>
      </Dialog.Root>

      <div className="mx-auto px-4 md:px-8">
        <div>
          <div className="items-center gap-x-3 mt-6 md:mt-0 flex">
            <Button
              className={
                orderType === "active"
                  ? "flex items-center text-white px-4 py-2 rounded-md shadow-sm bg-blue-600 hover:bg-blue-700 active:bg-blue-800 cursor-pointer"
                  : "flex py-2 px-4 border font-medium md:text-sm rounded-md bg-white text-gray-700 items-center justify-center gap-2"
              }
              onClick={() => handleOrderType("active")}
            >
              Active
            </Button>
            <Button
              onClick={() => {
                handleOrderType("completed");
              }}
              className={
                orderType === "completed"
                  ? "flex items-center text-white px-4 py-2 rounded-md shadow-sm bg-blue-600 hover:bg-blue-700 active:bg-blue-800 cursor-pointer"
                  : "flex py-2 px-4 border font-medium md:text-sm rounded-md bg-white text-gray-700 items-center justify-center gap-2"
              }
            >
              Completed
            </Button>
          </div>
        </div>
        {orderType === "active" ? (
          <>
            {isLoadingOrders ? (
              <div className="fixed top-0 bottom-0 left-0 right-0 flex mx-auto items-center justify-center h-dvh">
                <div className="bg-gray-700 absolute inset-0 opacity-50"></div>
                <SyncLoader size={30} color="blue" className="relative" />
              </div>
            ) : (
              <Tabs
                tab={completedOrder?.map((order) => ({
                  ...order,
                  items: order?.items?.filter(
                    (item: { order_state: string }) =>
                      item.order_state === "awaiting pick-up"
                  ),
                }))}
                handleViewOrder={handleViewOrder}
              />
            )}
          </>
        ) : (
          <>
            <Tabs
              tab={completedOrder?.map((order) => ({
                ...order,
                items: order?.items?.filter(
                  (item: { order_state: string }) =>
                    item?.order_state?.toLowerCase() === "completed"
                ),
              }))}
              handleViewOrder={handleViewOrder}
            />
          </>
        )}
      </div>
      {/* Quotation modal */}
      <TransitionsModal
        open={isOpen}
        setOpen={setIsOpen}
        handleCloseModal={handleCloseModal}
        title={"Get Quotation"}
        style={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          // width: "100%",
          bgcolor: "white",
          boxShadow: 24,
          borderRadius: "10px",
        }}
      >
        {!isContinue && (
          <>
            <PaymentMethods
              handlePaymentType={handlePaymentType}
              paymentType={paymentType}
            />
            <div className="w-full flex justify-center items-center my-3">
              <button
                onClick={() => {
                  if (paymentType === "") {
                    toastService.error("Please select a payment method");
                    return;
                  }
                  setIsContinue(true);
                }}
                className="bg-blue-500 text-white px-4 py-2 rounded-md shadow-sm hover:bg-blue-400 ease-in-out duration-300 delay-150"
              >
                Continue
              </button>
            </div>
          </>
        )}
        {isContinue && (
          <div className="lg:h-96">
            <h2 className="font-semibold lg:text-3xl mb-8">
              When do you need your quotation?
            </h2>

            <div className="grid lg:grid-cols-3 w-full gap-4">
              <button
                disabled={isPending}
                onClick={() => handleSubmitUpload("In 24 hours")}
                className="lg:h-32 bg-smokeWhite border    flex flex-col items-center justify-center w-full group px-4 py-2 font-semibold text-sm bg-blue-500 text-white rounded-md shadow-sm  hover:scale-105 ease-in-out duration-300 delay-150"
              >
                <span className="font-semibold text-lg">24 </span>
                {period === "In 24 hours" && <SyncLoader />} In 24 hours
              </button>
              <button
                disabled={isPending}
                onClick={() => handleSubmitUpload("In 3 days")}
                className="lg:h-32 bg-smokeWhite border    flex flex-col items-center justify-center w-full group px-4 py-2 font-semibold text-sm bg-blue-500 text-white rounded-md shadow-sm  hover:scale-105 ease-in-out duration-300 delay-150"
              >
                <span className="font-semibold text-lg">3</span>
                {period === "In 3 days" && <SyncLoader />}In Three days
              </button>
              <button
                disabled={isPending}
                onClick={() => handleSubmitUpload("In 7 days")}
                className="lg:h-32 bg-smokeWhite border    flex flex-col items-center justify-center w-full group px-4 py-2 font-semibold text-sm bg-blue-500 text-white rounded-md shadow-sm  hover:scale-105 ease-in-out duration-300 delay-150"
              >
                <span className="font-semibold text-lg">7</span>
                {period === "In 7 days" && <SyncLoader />}In Seven days
              </button>
              <div className="hidden lg:block"></div>
              <button
                disabled={isPending}
                onClick={() => handleSubmitUpload("In 10 days")}
                className="lg:h-32 bg-smokeWhite border flex flex-col items-center justify-center w-full group px-4 py-2 font-semibold text-sm bg-blue-500 text-white rounded-md shadow-sm  hover:scale-105 ease-in-out duration-300 delay-150"
              >
                <span className="font-semibold text-lg  ">10</span>{" "}
                {period === "In 10 days" && <SyncLoader />}
                <span className="flex group-hover:text-white">
                  {" "}
                  In Ten days
                </span>
              </button>
              <div></div>
            </div>
          </div>
        )}
      </TransitionsModal>

      {/* order details modal */}
      <TransitionsModal
        open={openOrderDetails}
        setOpen={setOpenOrderDetails}
        handleCloseModal={handleCloseOrderDetailModal}
        title={"Order Details"}
        style={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          // width: "80%",
          bgcolor: "white",
          boxShadow: 24,
          borderRadius: "10px",
        }}
        footer={
          <>
            {orderDetail?.order_state?.toLowerCase() !== "completed" && (
              <Button
                onClick={() =>
                  handleTrackOrder(
                    orderDetail?.order_by as string,
                    orderDetail?.id as string,
                    orderDetail?.product as string
                  )
                }
                className="text-white font-medium hover:text-gray-500 hover:opacity-85"
              >
                Track Order
              </Button>
            )}
          </>
        }
      >
        <div className="">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-1 w-full">
            <div className="place-self-center">
              {orderDetail?.product === "blood" && (
                <img
                  src="./images/blood.png"
                  alt=""
                  className="flex-none w-52 h-44 rounded-md object-contain bg-red-200 p-1"
                />
              )}
              {orderDetail?.product === "oxygen" && (
                <img
                  src="./images/oxygen.png"
                  alt=""
                  className="flex-none w-52 h-44 rounded-md object-contain bg-blue-200 p-1"
                />
              )}
            </div>
            <div className="grid grid-cols-1 gap-1 md:grid-cols-2 w-full">
              <div className=" flex flex-col space-y-5 w-full">
                <div className="">
                  <h3 className="font-semibold capitalize">Product</h3>
                  <p>
                    {orderDetail?.type} {orderDetail?.product}
                  </p>
                  <p className="capitalize">{orderDetail?.urgency}</p>
                </div>

                {orderDetail?.product === "blood" && (
                  <div className="">
                    <h3 className="font-semibold capitalize">Usage</h3>
                    <p> {orderDetail?.usage}</p>
                  </div>
                )}
                <div className="">
                  <h3 className="font-semibold capitalize">Quantity</h3>
                  <p>{orderDetail?.qty}</p>
                </div>
              </div>
              <div className="space-y-5 w-full">
                <div>
                  <h3 className="font-semibold">Shipping address</h3>
                  <p>{orderDetail?.hospital_name}</p>
                  <p>{hospital?.address}</p>
                </div>{" "}
                <div>
                  <h3 className="font-semibold">Payment Method</h3>

                  <p>{orderDetail?.payment}</p>
                </div>
                <div>
                  <h3 className="font-semibold">Status</h3>
                  <div>
                    <p className="text-green-600 bg-green-50 capitalize">
                      {orderDetail?.order_state}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </TransitionsModal>
    </div>
  );
}
