import { CountryModel, OneHospitalUser } from "../ts-utils/interfaces";
import React, { createContext, useContext, useState } from "react";
import { GlobalContextProps } from "../ts-utils/interfaces";
import countryService from "../services/countryService";
import { resetBaseUrl, updateBaseUrl } from "../services/httpService";

import { getCurrentUser } from "../services/authService";
import navigationRoutes from "../routes/navigationRoutes";

const GlobalContext = createContext<GlobalContextProps | null>(null);

export const useGlobalContext = () => {
  const context = useContext(GlobalContext);
  if (!context) {
    throw new Error(
      "useGlobalContext must be used within a GlobalContextProvider"
    );
  }
  return context;
};

export const GlobalContextProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const currentUser = getCurrentUser();
  const userCountry = countryService.getSelectedCountry();
  const [token, setAuthToken] = useState<string | null>(null);
  const [user, setUser] = useState<OneHospitalUser | null>(
    currentUser ? currentUser : null
  );
  const [country, setCountry] = useState<CountryModel | null>(
    userCountry ? userCountry : null
  );
  const [city] = useState<string>(user?.city.toLowerCase() ?? "lagos");
  const [pitches, setPitches] = useState<{
    pitch: string[];
    loading: boolean;
  }>({
    pitch: [],
    loading: false,
  });
  const [activeTab, setActiveTab] = useState<string>("focus");

  const handleCountrySelect = (selectedCountry: CountryModel): void => {
    setCountry(selectedCountry);
    countryService.saveSelectedCountry(selectedCountry);
    // countryService.saveCountryUrl(selectedCountry?.countryUrl);
    countryService.saveCountryUrl(selectedCountry?.testUrl);

    const savedCountryUrl: string | null = countryService.getCountryUrl();

    if (savedCountryUrl === "https://one.lifebanktest.com") {
      updateBaseUrl(savedCountryUrl as string);
    } else {
      resetBaseUrl(savedCountryUrl as string);
    }
  };

  const logout = () => {
    window.location.href = navigationRoutes.logout;
  };

  return (
    <GlobalContext.Provider
      value={{
        token,
        setAuthToken,
        user,
        setUser,
        country,
        setCountry,
        pitches,
        setPitches,
        handleCountrySelect,
        city,
        logout,
        activeTab,
        setActiveTab,
      }}
    >
      {children}
    </GlobalContext.Provider>
  );
};
