import React from "react";
import { useGlobalContext } from "../../../../contexts/globalContext";
import { useGetRewardValues } from "../../../../hooks/agent/useAgent";
import utilities from "../../../../utils/utilities";

export default function Reward({
  title,
  type,
  status,
}: {
  title: string;
  type: string;
  status: string;
}) {
  const { user, country } = useGlobalContext();

  const { values } = useGetRewardValues(type, status, user?.id || "");
  // console.log(values);
  return (
    <div className="grid lg:grid-cols-12 w-full gap-2">
      {/* <div className="lg:col-span-7 p-3 rounded-lg shadow-md">
        <h5 className="text-2xl">Congratulations {user?.username}! 🎉</h5>
        <p className="text-lg">You have done 70% more sales today.</p>
      </div> */}
      {/* <div className="col-span-full lg:col-span-5 w-full place-items-center">
        <div className="flex justify-between gap-3 w-full">
          <div className="w-full p-3 rounded-lg shadow-md">
            <div>
              <h2 className="text-lg font-bold">{type}</h2>
              <p className="text-xl font-semibold">{values?.data}</p>
            </div>
          </div>
        </div>
      </div> */}

      <div className="col-span-full mb-5 w-full gap-4">
        <div
          className={
            title === "Deduction"
              ? "bg-red-100 p-4 space-y-3 rounded-lg shadow-md"
              : title === "Reward"
              ? `p-4 space-y-3 bg-blue-100 rounded-lg shadow-md`
              : `p-4 space-y-3 bg-green-100 rounded-lg shadow-md`
          }
        >
          <h2 className="text-xl font-semibold">{title}</h2>
          <p className="text-gray-700">
            {utilities.currencyFormatter(values.data ?? 0, country?.currency)}
          </p>
        </div>
      </div>
    </div>
  );
}
