import ExodusHeader from "../../../../components/exodusComponent/ExodusHeader";
import {
  AverageCard,
  ContactInformationCard,
  HospitalActivityStream,
  ProductCard,
  // RewardStation,
  SmallCard,
  SuccessRelation,
} from "../../../../components/exodusComponent/Card";
// import frequency from "../../../../assets/Frequency.png";
import moneySpent from "../../../../assets/EstimatedGrowth.png";
import potential from "../../../../assets/PaymentHistory.png";

import {
  scubatank,
  equipment,
  box,
  blood,
  // transistion,
  // dollar,
  // phoneShaking,
  // hero,
  // herogo,
  // pdf,
} from "../../../../components/exodusComponent/Image";

import { FcCheckmark } from "react-icons/fc";
import { RiCheckboxBlankCircleLine } from "react-icons/ri";
import { useGetSingleActiveHospital } from "../../../../hooks/useHospital";

import { useGlobalContext } from "../../../../contexts/globalContext";
import { useNavigate, useParams } from "react-router";
import { useGetAllExodus } from "../../../../hooks/useExodus";
import { useCallback, useState } from "react";
import { useLocation } from "react-router-dom";
import { SyncLoader } from "react-spinners";

import { Button } from "../../../../@/components/ui/button";
import AddToPartnerProgram from "../../../../components/modals/AddToPartnerProgram";
import PointHistory from "../../../../components/modals/HistoryModal";
import RequestTable, { HistoryTable } from "./RequestHistory";
import { getPrivileges } from "../../../../services/authService";
import DateCell from "../../../../components/DateCell";
import { useGetSingleHospitalChurnStatus } from "../../../../hooks/agent/useAgent";

const SingleHospital = () => {
  const privileges = getPrivileges();
  const [addPartner, setAddPartner] = useState(false);
  const [pointHistory, openPointHistory] = useState(false);
  const history = useNavigate();
  const location = useLocation();
  const { state } = location;
  const status = state?.status?.toLowerCase();

  const { user } = useGlobalContext();

  const { id } = useParams();
  const { singleHospital, isPending } = useGetSingleActiveHospital(
    id as string
  );

  // const items = [
  //   {
  //     text: "Data Reward",
  //     image: transistion,
  //     textValue: "10GB",
  //     subText: "Available",
  //   },
  //   {
  //     text: "Cash Reward",
  //     image: dollar,
  //     textValue: "N20,000",
  //     subText: "Available",
  //   },
  //   {
  //     text: "Airtime Reward",
  //     image: phoneShaking,
  //     textValue: "N5,000",
  //     subText: "Available",
  //   },
  // ];

  // const dripStation = [
  //   { text: "Data Reward", image: hero, subText: "Available" },
  //   { text: "Cash Reward", image: herogo, subText: "Available" },
  //   { text: "Airtime Reward", image: pdf, subText: "Available" },
  // ];

  const liteSum = singleHospital?.ordercounts?.reduce(
    (sum: number, item: { channel: string; total_sum: any }) => {
      if (item.channel === "" || item.channel === "Call-in") {
        return sum + Number(item.total_sum);
      }
      return sum;
    },
    0
  );

  // Object with channel "nerve"
  const nerveItem = singleHospital?.ordercounts?.find(
    (item: { channel: string }) => item.channel === "Nerve"
  );

  // Result with lite and nerve values
  const result = {
    lite: liteSum,
    nerve: nerveItem ? Number(nerveItem.total_sum) : 0,
  };

  const handleOnclick = () => {
    history(`/hospitals/${id}/log`, {
      state: {
        ref_id: singleHospital?.hospital_summary[0]?.ref_id,
        id: id,
        name: singleHospital?.hospital_summary[0]?.name,
        address: singleHospital?.hospital_summary[0]?.address,
        averageMonthlySpent: singleHospital?.average_monthly_spend,
        nerveOrder: result.nerve,
        lastOrder: singleHospital?.hospital_summary?.last_order_date,
        scheduleAmount: singleHospital?.schedule_amount,
      },
    });
  };
  const isLogCall =
    !!singleHospital?.hospital_summary?.[0]?.ref_id &&
    user?.position !== "customer_success" &&
    user?.position !== "cceo";

  const handleSendDrip = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    e.preventDefault();
    history(`/hospitals/exodus-send-drip`, {
      state: {
        ref_id: singleHospital?.hospital_summary[0]?.ref_id,
        id: id,
        name: singleHospital?.hospital_summary[0]?.name,
        address: singleHospital?.hospital_summary[0]?.address,
        averageMonthlySpent: singleHospital?.average_monthly_spend,
        lastOrder: singleHospital?.hospital_summary?.last_order_date,
        scheduleAmount: singleHospital?.schedule_amount,
      },
    });
  };

  const formatDate = (item: string) => {
    if (!item) return "N/A";
    const dateObj = new Date(item);

    // Array of month names
    var monthNames = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];

    // Extract day, month, and year
    var day = dateObj.getDate();
    var monthIndex = dateObj.getMonth();
    var year = dateObj.getFullYear();

    // Format the date
    var formattedDate = day + " " + monthNames[monthIndex] + " " + year;
    return formattedDate;
  };

  const [type, setType] = useState("oxygen");
  const getUserId = useCallback(
    (user: any) => {
      if (!privileges.createAgent) {
        return user;
      }
      return "";
    },
    [privileges.createAgent]
  );
  const filters = {
    user_id: getUserId(user?.id) as string,
    status: status,
    order_by: singleHospital?.hospital_summary?.[0]?.ref_id,
    type: type,
    limit: 5,
  };
  const { allExodus } = useGetAllExodus({ ...filters }, "single-hospital");
  const { churnStatus } = useGetSingleHospitalChurnStatus(
    singleHospital?.hospital_summary?.[0]?.ref_id
  );
  console.log(churnStatus);

  const recentActivities = [
    {
      label: "Oxygen",
      value: "oxygen",
      title: "Oxygen Activities",
      color: "#5286c3",
      items: allExodus
        ? allExodus
            .map(
              (item: {
                id: string;
                order_by: string;
                payment: string;
                qty: string;
                tym: string;
                urgency: string;
                product: string;
                unitprice: string;
                paymentdetails: string;
                schedule_date: string;
                schedule_time: string;
                order_state: string;
                complete_tym: string;
                created_at: string;
                hospital_name: string;
                cylinder: string;
              }) => {
                const formatDate = (
                  dateString: string | number
                ): JSX.Element => {
                  return (
                    <DateCell dateString={dateString} format="datetime-12" />
                  );
                };
                return {
                  id: item.id,
                  order_by: item.order_by,
                  payment: item.payment,
                  qty: item.qty,
                  tym: item.tym,
                  urgency: item.urgency,
                  product: item.product,
                  unitprice: item.unitprice,
                  paymentdetails: item.paymentdetails,
                  schedule_date: item.schedule_date,
                  schedule_time: item.schedule_time,
                  order_state: item.order_state,
                  complete_tym: item.complete_tym,
                  created_at: formatDate(item.tym),
                  hospital_name: item.hospital_name,
                  cylinder: item.cylinder,
                };
              }
            )
            .filter((item: { id: null }) => item.id !== null)
        : [],
    },

    {
      label: "Blood",
      value: "blood",
      title: "Blood Activities",
      color: "#b70303",
      items: allExodus
        ? allExodus
            .map((item: { [x: string | number]: string | number }) => {
              const formatDate = (dateString: string | number): JSX.Element => {
                return (
                  <DateCell dateString={dateString} format="datetime-12" />
                );
              };

              return {
                id: item.order_id,
                cylinder: item.b_type,
                urgency: item.Request_Type,
                payment: item.payment_type,
                order_by: item.order_by,
                qty: item.qty,
                tym: item.tym,
                product: item.product,
                unitprice: item.unitprice,
                paymentdetails: item.paymentdetails,
                schedule_date: item.schedule_date,
                schedule_time: item.schedule_time,
                order_state: item.order_state,
                complete_tym: item.complete_tym,
                created_at: formatDate(item.tym),
                hospital_name: item.hospital_name,
              };
            })
            .filter((item: { id: null }) => item.id !== null)
        : [],
    },
    {
      label: "Quip",
      value: "quip",
      title: "Quip Activities",
      color: "#F16C03",
      items: allExodus
        ? allExodus
            .map(
              (item: {
                id: string;
                order_by: string;
                payment: string;
                qty: string;
                tym: string;
                urgency: string;
                product: string;
                unitprice: string;
                paymentdetails: string;
                schedule_date: string;
                schedule_time: string;
                order_state: string;
                complete_tym: string;
                created_at: string;
                hospital_name: string;
                b_type: string;
              }) => ({
                id: item.id,
                order_by: item.order_by,
                payment: item.payment,
                qty: item.qty,
                tym: item.tym,
                urgency: item.urgency,
                product: item.product,
                unitprice: item.unitprice,
                paymentdetails: item.paymentdetails,
                schedule_date: item.schedule_date,
                schedule_time: item.schedule_time,
                order_state: item.order_state,
                complete_tym: item.complete_tym,
                created_at: item.created_at,
                hospital_name: item.hospital_name,
                cylinder: item.b_type,
              })
            )
            .filter((item: { id: null }) => item.id !== null)
        : [],
    },
    {
      label: "StockBank",
      value: "stockbank",
      title: "StockBank Activities",
      color: "#215200",
      items: allExodus
        ? allExodus

            .map((item: { [x: string | number]: string | number }) => {
              const formatDate = (dateString: string | number): JSX.Element => {
                return (
                  <DateCell dateString={dateString} format="datetime-12" />
                );
              };
              return {
                id: item.order_id,
                cylinder: item.b_type,
                urgency: item.Request_Type,
                payment: item.payment,
                order_by: item.order_by,
                qty: item.qty,
                tym: formatDate(item.tym),
                product: item.product,
                unitprice: item.unitprice,
                paymentdetails: item.paymentdetails,
                schedule_date: item.schedule_date,
                schedule_time: item.schedule_time,
                order_state: item.order_state,
                complete_tym: item.complete_tym,
                created_at: formatDate(item.tym),
                hospital_name: item.hospital_name,
              };
            })
            .filter((item: { id: null }) => item.id !== null)
        : [],
    },
  ];

  const productFilter = {
    user_id: user?.id as string,
    status: "exodus",
    order_by: singleHospital?.hospital_summary?.[0]?.ref_id,
    channel: "nerve",
  };
  const { allExodus: productsList } = useGetAllExodus(
    { ...productFilter },
    "single-exodus"
  );

  const products = [
    { text: "Blood", order: "has_blood_order", image: blood },
    { text: "Cylinder", order: "has_oxygen_order", image: scubatank },
    { text: "Equipement", order: "has_quip_order", image: equipment },
    { text: "Consumable", order: "has_stockbank_order", image: box },
  ];

  const productsWithIcons = products.map((product) => {
    const orderKey = product.order;

    const isAvailable =
      productsList?.some((item: { [x: string]: string }) => {
        return item[orderKey] === "Yes";
      }) ?? false;

    return {
      ...product,
      icon: isAvailable ? <FcCheckmark /> : <RiCheckboxBlankCircleLine />,
    };
  });

  const beneficiary = {
    tier: "Tier 1 Partner",
    name: "Beneficiary Name",
    role: "Administrator",
    phone: "+2347012355955",
    email: "admin@admin.com",
    sla: "no",
  };

  const historyPoints = [
    {
      id: "1",
      date: "August 21st 14:15",
      reward: "Cash redemption",
      amount: "N5001",
      beneficiary: "Hospital",
      status: "successful",
    },
    {
      id: "2",
      date: "August 21st 14:25",
      reward: "Cash redemption",
      amount: "N5400",
      beneficiary: "Hospital",
      status: "pending",
    },
    {
      id: "3",
      date: "August 21st 14:55",
      reward: "Cash redemption",
      amount: "N5020",
      beneficiary: "Hospital",
      status: "failed",
    },
  ];

  const transformedHistoryPoints: HistoryTable[] = historyPoints.map(
    (item) => ({
      label: item.reward, // Map any property to `label` as needed
      key: item.id, // Map any property to `key` as needed
      ...item,
    })
  );

  const columns = [
    { label: "Date/Time", key: "date" },
    { label: "Reward Type", key: "reward" },
    { label: "Amount", key: "amount" },
    { label: "Beneficiary", key: "beneficiary" },
    { label: "Status", key: "status" },
  ];

  return (
    <div className="h-full w-full relative flex flex-col flex-grow">
      {isPending ? (
        <div className="fixed top-0 bottom-0 left-0 right-0 flex mx-auto items-center justify-center h-dvh z-30">
          <div className="bg-gray-700 absolute inset-0 opacity-50"></div>
          <SyncLoader
            color="#3B82F6"
            loading={isPending}
            className="relative"
            size={30}
          />
        </div>
      ) : (
        <>
          <ExodusHeader
            Text={
              singleHospital?.hospital_summary?.[0]?.name ??
              "Hospital Details Not Found"
            }
            subText={""}
          />
          {!singleHospital?.hospital_summary?.[0]?.ref_id ? (
            <>
              <div className="h-screen flex flex-col gap-3 items-center justify-center text-center">
                Hospital Details Not Found. <br />
                {/* Kindly Pitch, Close and On-Board Hospital. */}
                <Button onClick={() => history(`/hospitals/${id}/history`)}>
                  View Hospital History
                </Button>
              </div>
            </>
          ) : (
            <>
              <div className="mb-2 mt-3 flex gap-6 w-full overflow-x-auto">
                {[
                  {
                    Text: "Hospital Name",
                    subText: singleHospital?.hospital_summary?.[0]?.name || "",
                  },
                  {
                    Text: "Churn Status",
                    subText: `${churnStatus?.status ?? "N/A"} ` || "",
                    link:
                      churnStatus?.status &&
                      `/full-churn?lastorderdate=${
                        churnStatus?.lastorderdate
                      }&page=1&search=${singleHospital?.hospital_summary?.[0]?.name
                        ?.replace(" ", "+")
                        ?.toLowerCase()}&status=${churnStatus?.status
                        ?.toLowerCase()
                        ?.replace("in ", "")}`,
                  },
                  {
                    Text: "Nerve Status",
                    subText:
                      singleHospital?.hospital_summary?.nerve_status?.toString() ===
                      "true"
                        ? "Yes"
                        : "No",
                  },
                  { Text: "Hospital Owner", subText: "N/A" },
                  {
                    Text: "Health Score",
                    subText: "Coming Soon!",
                  },
                  {
                    Text: "History",
                    subText: "Click here",
                    link: "/hospitals/" + id + "/history",
                  },
                ].map((item, index) => (
                  <SmallCard
                    key={index}
                    Text={item.Text}
                    subText={item.subText}
                    link={item.link}
                  />
                ))}
              </div>
              <div className="border-t border-gray-400 mt-3 mb-3"></div>

              <div className="flex flex-wrap md:flex-nowrap justify-between mb-3 gap-2">
                <div className="w-full md:w-1/5 flex-1">
                  <ContactInformationCard
                    text={"Contact Information"}
                    icon={<i className="fas fa-phone-alt"></i>}
                    subText={""}
                    mailtext={
                      singleHospital?.hospital_summary?.[0]?.email || "N/A"
                    }
                    phoneText={
                      singleHospital?.hospital_summary?.[0]?.phone || "N/A"
                    }
                    locationText={
                      singleHospital?.hospital_summary?.[0]?.address || "N/A"
                    }
                  />
                </div>
                <div className="w-full md:w-1/5 ">
                  <AverageCard
                    text={"Nerve Order"}
                    subText={result?.nerve?.toString()}
                    icon={potential}
                  />
                </div>
                <div className="w-full md:w-1/5 ">
                  <AverageCard
                    text={`Lite Order`}
                    subText={result?.lite?.toString()}
                    icon={moneySpent}
                  />
                </div>
                <div className="w-full md:w-1/5 ">
                  <div className="bg-[white] flex flex-col shadow-white shadow-sm rounded-lg border border-black p-2 h-[200px] items-start justify-around">
                    <p>
                      <span className="font-semibold">
                        Date of Last Order :
                      </span>{" "}
                      <span className="text-[#49209F] font-semibold">
                        {formatDate(
                          singleHospital?.hospital_summary?.last_order
                            ?.order_date
                        )}
                      </span>
                    </p>
                    <p>
                      <span className="font-semibold"> Channel :</span>{" "}
                      <span className="text-[#49209F] font-semibold">
                        {singleHospital?.hospital_summary?.last_order
                          ?.channel ?? "N/A"}
                      </span>
                    </p>
                    <p>
                      <span className="font-semibold">Product : </span>{" "}
                      <span className="text-[#49209F] font-semibold">
                        {singleHospital?.hospital_summary?.last_order
                          ?.product ?? "N/A"}
                      </span>
                    </p>
                  </div>
                  {/* <AverageCard
            text={"Ordering Frequency"}
            subText={singleHospital?.ordering_frequency}
            icon={frequency}
          /> */}
                </div>
                {/* <div className="">
          <div className="flex gap-2"></div>
        </div> */}
              </div>

              <div className="w-full lg:flex lg:flex-row gap-4 md:flex md:flex-col sm:flex-col sm:flex">
                <div className="w-full gap-3 mb-3 space-y-2">
                  <div className="grid md:grid-cols-3 w-full gap-2">
                    <div className="">
                      <ProductCard
                        headerText={"Products"}
                        items={productsWithIcons}
                      />
                    </div>
                    <div className="grid gap-3">
                      <div className=" bg-[#F7F3FE]  shadow-white shadow-sm flex flex-col items-center justify-center border border-black font-semibold text-2xl rounded-lg">
                        <div className="p-1">
                          <p className="text-[#49209F] text-[20px] font-bold p-1">
                            Reward Station
                          </p>
                        </div>
                        Coming Soon!
                        {/* <RewardStation headerText={"Reward Station"} items={items} /> */}
                      </div>
                      {user?.position === "sales_rep" ? (
                        <button
                          onClick={handleSendDrip}
                          className=" bg-[#F7F3FE] hover:bg-purple-200 shadow-white shadow-sm flex flex-col items-center justify-center border border-black font-semibold text-2xl rounded-lg"
                        >
                          Send Drip!
                          {/* <RewardStation headerText={"Drip Station"} items={dripStation} /> */}
                        </button>
                      ) : (
                        <div className=" bg-[#F7F3FE]  shadow-white shadow-sm flex flex-col items-center justify-center border border-black font-semibold text-2xl rounded-lg ">
                          <div className="p-1">
                            <p className="text-[#49209F] text-[20px] font-bold p-1">
                              Drip Station
                            </p>
                          </div>
                          Coming Soon!
                          {/* <RewardStation headerText={"Drip Station"} items={dripStation} /> */}
                        </div>
                      )}
                    </div>
                    <div className="bg-[#F7F3FE] text-center shadow-white shadow-sm flex flex-col items-center justify-center border border-black font-semibold text-2xl rounded-lg gap-4 p-2">
                      <p className="text-[#49209F] text-[20px] font-bold p-1">
                        Nerve Partner Program
                      </p>
                      <p>
                        {beneficiary
                          ? `Active: ${beneficiary.tier}`
                          : "Not Active"}
                      </p>
                      <div>
                        {beneficiary ? (
                          <div className="flex md:flex-col xl:flex-row gap-2">
                            <Button
                              variant={"outline"}
                              onClick={() => setAddPartner(true)}
                              className="!border-[#49209F] text-[#49209F] px-2 bg-transparent font-semibold"
                            >
                              Beneficiary Details
                            </Button>
                            <Button
                              onClick={() => openPointHistory(true)}
                              className="bg-[#49209F] hover:bg-purple-900 px-2"
                            >
                              Point History
                            </Button>
                          </div>
                        ) : (
                          <>
                            <Button
                              onClick={() => setAddPartner(true)}
                              className="bg-[#49209F] hover:bg-purple-900 px-3"
                            >
                              Add to Partner Program
                            </Button>
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                  {isLogCall && (
                    <div className="grid md:grid-cols-2 w-full gap-2">
                      <div className="w-full  ">
                        <SuccessRelation
                          Text={"Sales Relationship"}
                          subText={"Active"}
                          btnText={"Log or schedule a visit"}
                          onClick={() => handleOnclick()}
                        />
                      </div>
                      <div className="w-full  ">
                        <SuccessRelation
                          Text={"Success Relationship"}
                          subText={"Add value"}
                          btnText={"Log or schedule a visit"}
                          onClick={() => handleOnclick()}
                        />
                      </div>
                    </div>
                  )}
                </div>
                <div className="lg:w-4/12 md:w-full sm:w-full h-full sm:mb-4">
                  <HospitalActivityStream
                    items={recentActivities}
                    setType={setType}
                    type={type}
                    status={status}
                  />
                </div>
              </div>
            </>
          )}
        </>
      )}

      <AddToPartnerProgram
        addPartner={addPartner}
        setAddPartner={setAddPartner}
        beneficiary={beneficiary}
      />
      <PointHistory
        pointHistory={pointHistory}
        openPointHistory={openPointHistory}
        title="Request History"
      >
        <RequestTable
          isLoading={false}
          data={transformedHistoryPoints ?? []}
          columns={columns}
        />
      </PointHistory>
    </div>
  );
};
export default SingleHospital;
