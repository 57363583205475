import React, { useCallback, useEffect, useMemo, useState } from "react";

import Header from "../../../../components/blocks/header";
import SearchQuery from "../../../../components/forms/searchQuery";

import { useGetComplaint } from "../../../../hooks/useDashboard";
import { useGlobalContext } from "../../../../contexts/globalContext";
import { Link, useLocation, useNavigate } from "react-router-dom";
import applyFilters from "../../../../helpers/urlState";
import ProductTable, {
  ProductTableColumn,
} from "../../../../components/common/ProductTable";
import { Hospital } from "../../../../ts-utils/types";
import { SyncLoader } from "react-spinners";
import ReactSelect from "../../../../components/common/ReactSelect";

const filterStatus = [
  {
    value: "",
    label: "All",
  },
  {
    value: "pre-churn",
    label: "Pre Churn",
  },
  {
    value: "danger",
    label: "Danger",
  },
  {
    value: "add-value",
    label: "Add Value",
  },
  {
    value: "check-in",
    label: "Check in",
  },
  {
    value: "upsell",
    label: "Upsell",
  },
];
const HealthScorePage = (): JSX.Element => {
  const { user } = useGlobalContext();
  const location = useLocation();
  const navigate = useNavigate();
  const searchParams = useMemo(
    () => new URLSearchParams(location.search),
    [location.search]
  );
  const pathname = location.pathname.replace("/", "");

  const pageSize: number = 10;
  const [currentPage, setCurrentPage] = useState(1);
  const [status, setStatus] = useState<string>(
    searchParams.get("status") || ""
  );
  const [search, setSearch] = useState(searchParams.get("search") || "");

  const filters = {
    user_id: user?.id as string,
    limit: pageSize,
    page: currentPage,
    name: search,
    status: status,
  };

  const { complaints, isPending, paginate } = useGetComplaint(filters);

  function renderCell(hospital: Hospital, column: ProductTableColumn) {
    switch (column.accessor) {
      case "name":
        return (
          <>
            {hospital?.hospital_id ? (
              <Link
                className="text-[#49209F]  underline font-bold"
                to={`/hospitals/${hospital?.hospital_id}`}
              >
                {hospital?.name ?? "N/A"}
              </Link>
            ) : (
              <> {hospital?.name ?? "N/A"}</>
            )}
          </>
        );
      case "tym":
        const date = hospital[column.accessor]
          ? new Date(hospital[column.accessor] * 1000)
          : null;

        return (
          <span>
            {date
              ? `${date.getDate().toString().padStart(2, "0")}-${(
                  date.getMonth() + 1
                )
                  .toString()
                  .padStart(2, "0")}-${date.getFullYear()}`
              : "N/A"}
          </span>
        );

      default:
        return <span>{hospital[column.accessor] ?? "N/A"}</span>;
    }
  }
  const columns: ProductTableColumn[] = [
    { header: "Hospital Name", accessor: "name" },
    { header: "Product", accessor: "productlist" },
    { header: "Nerve Usage", accessor: "nerve" },
    { header: "Hot Complaint", accessor: "complaint" },
    {
      header: "Late Delivery",
      accessor: "late_delivery",
      className: "text-nowrap",
    },
    { header: "Referrals", accessor: "referral", className: "max-w-32 w-32" },
    { header: "Reward", accessor: "reward", className: "max-w-32 w-32" },
    { header: "Pay Plan", accessor: "plan" },
  ];

  useEffect(() => {
    const pageFromUrl = parseInt(searchParams.get("page") || "1", 10);
    if (pageFromUrl !== currentPage) {
      setCurrentPage(pageFromUrl);
    }
  }, [location.search, searchParams, currentPage]);

  const goToPage = useCallback(
    (page: number) => {
      applyFilters(
        {
          page,
          search,
        },
        navigate,
        pathname
      );
      setCurrentPage(page);
    },
    [navigate, pathname, search]
  );

  const handleStatusChange = (status: React.SetStateAction<string>) => {
    setStatus(status);
    navigate(`?status=${status}&page=${currentPage}`, {
      replace: true,
    });
  };

  const handleSearch = (e: any) => {
    setSearch(e);
    applyFilters(
      {
        page: 1,
        search: e,
        status,
      },
      navigate,
      pathname
    );
  };
  return (
    <React.Fragment>
      <Header title="Health Score" />

      {isPending ? (
        <div className="fixed top-0 bottom-0 left-0 right-0 flex mx-auto items-center justify-center h-dvh z-30">
          <div className="bg-gray-700 absolute inset-0 opacity-50"></div>
          <SyncLoader
            color="#3B82F6"
            loading={isPending}
            className="relative"
            size={30}
          />
        </div>
      ) : (
        <div className="hospitals-container">
          <div className="hospitals-container-top">
            <SearchQuery
              name="search"
              placeholder="Search"
              value={search}
              onChange={(e: any) => handleSearch(e.currentTarget.value)}
            />

            <div className="right-options">
              <ReactSelect
                placeholder="Filter by Status"
                defaultValue={status}
                options={filterStatus?.map(
                  (status: { value: string; label: string }) => ({
                    value: status.value,
                    label: status.label,
                  })
                )}
                onChange={handleStatusChange}
              />
            </div>
          </div>

          <div className="hospitals-container-bottom table-responsive mb-5">
            <>
              <ProductTable
                data={complaints ?? []}
                columns={columns}
                renderCell={renderCell}
                totalPages={paginate?.totalPages}
                currentPage={currentPage}
                goToPage={goToPage}
                noDataMessage={"You Currently don't have any Health Score"}
              />
            </>
          </div>
        </div>
      )}
    </React.Fragment>
  );
};

export default HealthScorePage;
