import React, { useCallback, useEffect, useMemo, useState } from "react";
import * as Dialog from "@radix-ui/react-dialog";

import Header from "../../../../components/blocks/header";
import SearchQuery from "../../../../components/forms/searchQuery";

import { getPrivileges } from "../../../../services/authService";

import { useGlobalContext } from "../../../../contexts/globalContext";
import { Link, useLocation, useNavigate } from "react-router-dom";
import applyFilters from "../../../../helpers/urlState";
import ProductTable, {
  ProductTableColumn,
} from "../../../../components/common/ProductTable";
import { Hospital } from "../../../../ts-utils/types";
import { SyncLoader } from "react-spinners";
import ReactSelect from "../../../../components/common/ReactSelect";
import { useGetAllExodus } from "../../../../hooks/useExodus";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "../../../../@/components/ui/dropdown-menu";
import { Button } from "../../../../@/components/ui/button";
import { Bell, MoreVertical } from "lucide-react";
import { z } from "zod";
import { useChurnNotify } from "../../../../hooks/agent/useAgent";
import { zodResolver } from "@hookform/resolvers/zod";
import { Controller, useForm } from "react-hook-form";
import { Separator } from "../../../../@/components/ui/separator";
import useGetDateRange from "../../../../hooks/dates/use-get-date-range";
import DateCell from "../../../../components/DateCell";

const updateSchema = z.object({
  note: z
    .string({ message: "Additional comment is required" })
    .min(1, "Additional comment is required is required"),
});

type UpdateFormData = z.infer<typeof updateSchema>;

const typeOption = [
  {
    value: "full",
    label: "Full",
  },
  {
    value: "partial",
    label: "Partial",
  },
  {
    value: "risk",
    label: "Risk",
  },
  {
    value: "exit",
    label: "In Exit",
  },
];
const FullChurnPage = (): JSX.Element => {
  const privileges = getPrivileges();
  const { user } = useGlobalContext();
  const location = useLocation();
  const navigate = useNavigate();
  const searchParams = useMemo(
    () => new URLSearchParams(location.search),
    [location.search]
  );
  const pathname = location.pathname.replace("/", "");

  const [search, setSearch] = useState(searchParams.get("search") || "");
  // const orderDate = searchParams.get("lastorderdate") || "";

  const [selectedType, setSelectedType] = useState<string | null>(
    searchParams.get("status") || "full"
  );

  const { getSeconds } = useGetDateRange(selectedType as string);

  const newDateRange = getSeconds().readable;

  // Split the date range into an array
  // const dateArray = newDateRange.split(",");

  // Update the start date to "946681200" if orderDate is available
  // if (orderDate) {
  //   dateArray[0] = "946681200";
  // }

  // Remove the last item from the array
  // const removedItem = dateArray.pop();

  // Conditionally add either `orderDate` or the removed item back
  // const splitnewDateRange = orderDate
  //   ? [...dateArray, orderDate]
  //   : [...dateArray, removedItem];

  // console.log(splitnewDateRange);
  // const resultString = splitnewDateRange.join(",");
  // console.log(resultString);

  const handleTypeChange = (option: string) => {
    setSelectedType(option);
    navigate(`?status=${option}&page=${currentPage}`, {
      replace: true,
    });
  };

  const [updateChurn, setUpdateChurn] = useState(false);
  const { mutateChurnNotify, isPending: isUpdating } = useChurnNotify();

  const pageSize: number = 10;
  const [currentPage, setCurrentPage] = useState(1);

  const getUserId = useCallback(
    (user: any) => {
      if (!privileges.createAgent) {
        return user;
      }
      return "";
    },
    [privileges.createAgent]
  );

  const filters = {
    user_id: getUserId(user?.id) as string,
    limit: pageSize,
    page: currentPage,
    name: search,
    churnstatus: selectedType === "partial" ? "not_null" : "",
    status: "churn",
    date_range: newDateRange,
  };

  const { allExodus, paginate, isPending } = useGetAllExodus(
    { ...filters },
    "churn"
  );

  const [hospitalInfoToUpdate, setHospitalInfoToUpdate] =
    useState<Hospital | null>(null);

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<UpdateFormData>({
    resolver: zodResolver(updateSchema),
  });

  const onSubmit = async (data: UpdateFormData) => {
    mutateChurnNotify(
      {
        ...data,
        hospital_id: hospitalInfoToUpdate.id,
        agent_id: hospitalInfoToUpdate.user_id,
        type: selectedType,
      },
      {
        onSuccess: () => {
          setUpdateChurn(false);
          setHospitalInfoToUpdate(null);
        },
      }
    );
  };

  const handleUpdateSelect = (hospital: Hospital): void => {
    setHospitalInfoToUpdate(hospital);

    setUpdateChurn(true);
  };

  function renderCell(hospital: Hospital, column: ProductTableColumn) {
    switch (column.accessor) {
      case "name":
        return (
          <>
            {hospital?.id ? (
              <Link
                className="text-[#49209F]  underline font-bold"
                to={`/hospitals/${hospital?.id}`}
              >
                {hospital?.name ?? "N/A"}
              </Link>
            ) : (
              <> {hospital?.name ?? "N/A"}</>
            )}
          </>
        );
      case "agent_notified_date":
        return (
          <DateCell
            dateString={hospital[column.accessor]}
            format="ordinal-short"
          />
        );
      case "status":
        return (
          <span
            style={{
              backgroundColor:
                hospital[column.accessor] === "open"
                  ? "#4CAF50"
                  : hospital[column.accessor] === "good"
                  ? "#3B82F6"
                  : hospital[column.accessor] === "close"
                  ? "#f44336"
                  : hospital[column.accessor] === "pending"
                  ? "#FFD700"
                  : "#f44336",
              color: "white",
              padding: "5px 10px",
              borderRadius: "20px",
              display: "inline-block",
              textTransform: "uppercase",
            }}
          >
            {hospital[column.accessor] ?? "N/A"}
          </span>
        );
      case "action":
        return (
          <div>
            <DropdownMenu>
              <DropdownMenuTrigger asChild>
                <Button variant="ghost" className="h-8 w-8 p-0">
                  <span className="sr-only">Open menu</span>
                  <MoreVertical className="h-4 w-4" />
                </Button>
              </DropdownMenuTrigger>
              <DropdownMenuContent align="end">
                <>
                  <DropdownMenuItem
                    className="cursor-pointer"
                    onClick={() => {
                      handleUpdateSelect(hospital);
                    }}
                  >
                    <Bell className="mr-2 h-4 w-4" /> Notify
                  </DropdownMenuItem>
                </>
              </DropdownMenuContent>
            </DropdownMenu>
          </div>
        );
      default:
        return <span>{hospital[column.accessor] ?? "N/A"}</span>;
    }
  }
  const columns: ProductTableColumn[] = [
    { header: "Hospital", accessor: "name" },
    { header: "Assigned", accessor: "username" },
    { header: "Product", accessor: "productlist" },
    {
      header: "Date",
      accessor: "agent_notified_date",
      className: "text-nowrap",
    },

    { header: "Reason", accessor: "reason", className: "max-w-32 w-32" },
  ];

  if (privileges.createAgent) {
    columns.push({
      header: "Next Steps",
      accessor: "action",
      className: "max-w-32 w-32",
    });
  }

  useEffect(() => {
    const pageFromUrl = parseInt(searchParams.get("page") || "1", 10);
    if (pageFromUrl !== currentPage) {
      setCurrentPage(pageFromUrl);
    }
  }, [location.search, searchParams, currentPage]);
  const goToPage = useCallback(
    (page: number) => {
      applyFilters(
        {
          page,
          search,
        },
        navigate,
        pathname
      );
      setCurrentPage(page);
    },
    [navigate, pathname, search]
  );

  const handleSearch = (e: any) => {
    setSearch(e);
    applyFilters(
      {
        page: 1,
        search: e,
      },
      navigate,
      pathname
    );
  };
  return (
    <React.Fragment>
      <Header title="Full Churn" />

      {isPending ? (
        <div className="fixed top-0 bottom-0 left-0 right-0 flex mx-auto items-center justify-center h-dvh z-30">
          <div className="bg-gray-700 absolute inset-0 opacity-50"></div>
          <SyncLoader
            color="#3B82F6"
            loading={isPending}
            className="relative"
            size={30}
          />
        </div>
      ) : (
        <div className="hospitals-container">
          <div className="hospitals-container-top">
            <SearchQuery
              name="search"
              placeholder="Search"
              value={search}
              onChange={(e: any) => handleSearch(e.currentTarget.value)}
            />

            <div className="right-options gap-5">
              <div className="w-full self-end">
                <label
                  htmlFor="type-select"
                  className="block text-sm font-medium text-gray-700 mb-1"
                >
                  Select Type
                </label>
                <ReactSelect
                  defaultValue={selectedType as string}
                  options={typeOption?.map(
                    (status: { value: string; label: string }) => ({
                      value: status.value,
                      label: status.label,
                    })
                  )}
                  onChange={handleTypeChange}
                  placeholder="Select type..."
                  className="w-full"
                />
              </div>
            </div>
          </div>

          <div className="hospitals-container-bottom table-responsive mb-5">
            <>
              <ProductTable
                data={allExodus ?? []}
                columns={columns}
                renderCell={renderCell}
                totalPages={paginate?.totalPages}
                currentPage={currentPage}
                goToPage={goToPage}
                noDataMessage={"You Currently don't have any churn hospitals"}
              />
            </>
          </div>
        </div>
      )}

      <Dialog.Root open={updateChurn} onOpenChange={setUpdateChurn}>
        <Dialog.Portal>
          <Dialog.Overlay className="data-[state=open]:animate-overlayShow fixed inset-0 w-full h-full bg-black opacity-40" />
          <Dialog.Content
            aria-describedby={undefined}
            className="fixed top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%] w-full max-w-xl mx-auto px-1"
          >
            <div className="bg-white rounded-md shadow-lg py-3">
              <div className="flex items-center justify-between px-4">
                <Dialog.Title className="text-lg font-medium text-gray-800 flex items-center flex-wrap">
                  <span className="text-nowrap font-semibold mr-2">
                    Notify Agent
                  </span>
                </Dialog.Title>
                <Dialog.Close
                  className="p-2 text-black rounded-md hover:bg-gray-100"
                  onClick={() => {}}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="w-5 h-5 mx-auto"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                  >
                    <path
                      fillRule="evenodd"
                      d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                      clipRule="evenodd"
                    />
                  </svg>
                </Dialog.Close>
              </div>
              <Separator className="my-2 bg-black" />
              <div className="mx-auto space-y-3 text-start ">
                <div className="flex items-center justify-center p-4 w-full">
                  <form
                    onSubmit={handleSubmit(onSubmit)}
                    className="space-y-4 w-full"
                  >
                    <div className="flex gap-2">
                      <p className="font-semibold">Agent-In-Charge:</p>{" "}
                      <span>{hospitalInfoToUpdate?.username}</span>
                    </div>
                    <div>
                      <label className="font-semibold mb-2">Comment</label>{" "}
                      <Controller
                        name="note"
                        control={control}
                        render={({ field }) => (
                          <textarea
                            {...field}
                            placeholder="Add Additional comment"
                            className={`w-full p-1 border ${
                              errors.note
                                ? "!border-red-500"
                                : "border-gray-300"
                            } rounded-md focus:ring-2 focus:ring-indigo-500`}
                          />
                          // <ReactSelect
                          //   placeholder="Filter by Status"
                          //   options={typeOption.map((status) => ({
                          //     value: status.value,
                          //     label: status.label,
                          //   }))}
                          //   onChange={(selectedOption) =>
                          //     field.onChange(selectedOption)
                          //   }
                          // />
                        )}
                      />{" "}
                      {errors.note && (
                        <p className="text-red-500 text-sm mt-1">
                          {errors.note.message}
                        </p>
                      )}
                    </div>

                    <Button
                      disabled={isUpdating}
                      type="submit"
                      className="w-full p-3 bg-blue-600 text-white rounded-md shadow-md transition-all"
                    >
                      {isUpdating ? "Submitting..." : "Submit"}
                    </Button>
                  </form>
                </div>
              </div>
            </div>
          </Dialog.Content>
        </Dialog.Portal>
      </Dialog.Root>

      {/* <ChurnPipeline
        activeCategory={activeCategory}
        onActiveChange={(category: any) => setActiveCategory(category)}
        data={{
          newly_assign: 0,
          assign: 0,
          pitch: 0,
          close: 0,
          exodus: 0,
          active: 0,
          onboard: 0,
          imported: 0,
        }}
      /> */}
    </React.Fragment>
  );
};

export default FullChurnPage;
