import { Dispatch, SetStateAction } from "react";

import * as Dialog from "@radix-ui/react-dialog";
import { Separator } from "../../@/components/ui/separator"; 

export default function PointHistory({
  pointHistory,
  openPointHistory, 
  children,
  title,
}: {
  pointHistory: boolean;
  openPointHistory: Dispatch<SetStateAction<boolean>>; 
  children: React.ReactNode;
  title?: string;
}) {
  return (
    <div>
      <Dialog.Root open={pointHistory} onOpenChange={openPointHistory}>
        <Dialog.Portal>
          <Dialog.Overlay className="data-[state=open]:animate-overlayShow fixed inset-0 w-full h-full bg-black opacity-40" />
          <Dialog.Content
            aria-describedby={undefined}
            className="fixed top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%] w-full max-w-3xl mx-auto px-1"
          >
            <div className="bg-white rounded-md shadow-lg py-3">
              <div className="flex items-center justify-between px-4">
                <Dialog.Title className="text-lg font-medium text-gray-800 flex items-center">
                  <span className="text-nowrap font-semibold mr-2">
                   {title}
                  </span>
                </Dialog.Title>
                <Dialog.Close
                  className="p-2 text-black rounded-md hover:bg-gray-100"
                  onClick={() => {}}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="w-5 h-5 mx-auto"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                  >
                    <path
                      fillRule="evenodd"
                      d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                      clipRule="evenodd"
                    />
                  </svg>
                </Dialog.Close>
              </div>
              <Separator className="my-2 bg-black" />
              <div className="mx-auto space-y-3 text-start ">
                <div className="flex items-center justify-center p-4 w-full">
                  {children}
                </div>
              </div>
            </div>
          </Dialog.Content>
        </Dialog.Portal>
      </Dialog.Root>
    </div>
  );
}
