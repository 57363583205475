import React, { useCallback, useEffect, useMemo, useState } from "react";

import Header from "../../../../components/blocks/header";

import { getPrivileges } from "../../../../services/authService";

import { useGlobalContext } from "../../../../contexts/globalContext";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import applyFilters from "../../../../helpers/urlState";
import ProductTable, {
  ProductTableColumn,
} from "../../../../components/common/ProductTable";
import { Hospital } from "../../../../ts-utils/types";
import { SyncLoader } from "react-spinners";

import {
  useGetHospitalCancelledOrder,
  useGetHospitalRating,
} from "../../../../hooks/useHospital";
import { Button } from "../../../../@/components/ui/button";
import Rating from "@mui/material/Rating";
import { cn } from "../../../../@/lib/utils";
import { useQueryClient } from "@tanstack/react-query";
import { useGetComplaint, useGetFeed } from "../../../../hooks/useDashboard";
import DateCell from "../../../../components/DateCell";
// import useDateString from "../../../../hooks/dates/useDateString";

// const DateCell = ({ dateString }: { dateString: string }) => {
//   // Parse the date string directly without multiplication
//   const date = dateString ? new Date(dateString) : null;
//   const formattedDate = useDateString(date || new Date(), "datetime-12");

//   return <span>{date && !isNaN(date.getTime()) ? formattedDate : "N/A"}</span>;
// };
const HospitalHistory = (): JSX.Element => {
  const queryClient = useQueryClient();
  const privileges = getPrivileges();
  const { id } = useParams();

  const { user } = useGlobalContext();
  const location = useLocation();
  const navigate = useNavigate();
  const searchParams = useMemo(
    () => new URLSearchParams(location.search),
    [location.search]
  );
  const pathname = location.pathname.replace("/", "");
  const getUserId = useCallback(
    (user: any) => {
      if (!privileges.createAgent) {
        return user;
      }
      return "";
    },
    [privileges.createAgent]
  );
  const pageSize: number = 5;
  const [currentPage, setCurrentPage] = useState(1);
  const [history, setHistory] = useState({
    note: false,
    rating: false,
    complaint: false,
    cancelled: true,
  });

  const filters = {
    user_id: getUserId(user?.id) as string,
    limit: pageSize,
    page: currentPage,
    hospital_id: id,
  };
  const complaintFilters = {
    user_id: user?.id as string,
    limit: pageSize,
    page: currentPage,
    hospital_id: id,
  };

  const noteFilters = {
    user_id: getUserId(user?.id) as string,
    limit: pageSize,
    page: currentPage,
    hospitalid: id,
  };

  const { cancelledOrder, paginate, isPending } =
    useGetHospitalCancelledOrder(filters);

  const {
    rating,
    paginate: paginateRating,
    isPending: ratingIsPending,
  } = useGetHospitalRating(filters);

  const {
    complaints,
    paginate: paginateComplaints,
    isPending: complaintsIsPending,
  } = useGetComplaint(complaintFilters);

  const {
    feed,
    isPending: isPendingNote,
    paginate: paginatingNote,
  } = useGetFeed(noteFilters);

  // function renderCell(hospital: Hospital, column: ProductTableColumn) {
  //   const date = hospital[column.accessor]
  //     ? new Date(hospital[column.accessor] * 1000)
  //     : null;
  //   switch (column.accessor) {
  //     case "name":
  //       return (
  //         <>
  //           {hospital?.hospital_id ? (
  //             <Link
  //               className="text-[#49209F]  underline font-bold"
  //               to={`/hospitals/${hospital?.hospital_id}`}
  //             >
  //               {hospital?.name ?? "N/A"}
  //             </Link>
  //           ) : (
  //             <> {hospital?.name ?? "N/A"}</>
  //           )}
  //         </>
  //       );
  //     case "tym":
  //       return (
  //         <span>
  //           {date
  //             ? `${date.toLocaleDateString("en-GB", {
  //                 day: "2-digit",
  //                 month: "2-digit",
  //                 year: "numeric",
  //               })} ${date.toLocaleTimeString("en-US", {
  //                 hour: "numeric",
  //                 minute: "numeric",
  //                 hour12: true,
  //               })}`
  //             : "N/A"}
  //         </span>
  //       );

  //     case "cancel_tym":
  //       return (
  //         <span>
  //           {date
  //             ? `${date.toLocaleDateString("en-GB", {
  //                 day: "2-digit",
  //                 month: "2-digit",
  //                 year: "numeric",
  //               })} ${date.toLocaleTimeString("en-US", {
  //                 hour: "numeric",
  //                 minute: "numeric",
  //                 hour12: true,
  //               })}`
  //             : "N/A"}
  //         </span>
  //       );
  //     case "order_date":
  //       return (
  //         <span>
  //           {date
  //             ? `${date.toLocaleDateString("en-GB", {
  //                 day: "2-digit",
  //                 month: "2-digit",
  //                 year: "numeric",
  //               })} ${date.toLocaleTimeString("en-US", {
  //                 hour: "numeric",
  //                 minute: "numeric",
  //                 hour12: true,
  //               })}`
  //             : "N/A"}
  //         </span>
  //       );

  //     case "complete_tym":
  //       return (
  //         <span>
  //           {date
  //             ? `${date.toLocaleDateString("en-GB", {
  //                 day: "2-digit",
  //                 month: "2-digit",
  //                 year: "numeric",
  //               })} ${date.toLocaleTimeString("en-US", {
  //                 hour: "numeric",
  //                 minute: "numeric",
  //                 hour12: true,
  //               })}
  //                 `
  //             : "N/A"}
  //         </span>
  //       );

  //     case "rate":
  //       return (
  //         <span className="flex">
  //           <Rating
  //             precision={0.5}
  //             name="read-only"
  //             value={hospital[column.accessor]}
  //             readOnly
  //           />
  //         </span>
  //       );

  //     case "status":
  //       return (
  //         <span
  //           style={{
  //             backgroundColor:
  //               hospital[column.accessor] === "open"
  //                 ? "#4CAF50"
  //                 : hospital[column.accessor] === "good"
  //                 ? "#3B82F6"
  //                 : hospital[column.accessor] === "close"
  //                 ? "#f44336"
  //                 : hospital[column.accessor] === "pending"
  //                 ? "#FFD700"
  //                 : "#f44336",
  //             color: "white",
  //             padding: "5px 10px",
  //             borderRadius: "20px",
  //             display: "inline-block",
  //             textTransform: "uppercase",
  //           }}
  //         >
  //           {hospital[column.accessor] ?? "N/A"}
  //         </span>
  //       );

  //     default:
  //       return <span>{hospital[column.accessor] ?? "N/A"}</span>;
  //   }
  // }

  function renderCell(hospital: Hospital, column: ProductTableColumn) {
    switch (column.accessor) {
      case "name":
        return (
          <>
            {hospital?.hospital_id ? (
              <Link
                className="text-[#49209F] underline font-bold"
                to={`/hospitals/${hospital?.hospital_id}`}
              >
                {hospital?.name ?? "N/A"}
              </Link>
            ) : (
              <> {hospital?.name ?? "N/A"}</>
            )}
          </>
        );

      case "tym":
      case "cancel_tym":
      case "order_date":
      case "complete_tym":
      case "date":
        return (
          <DateCell
            dateString={hospital[column.accessor]}
            format="datetime-12"
          />
        );

      case "rate":
        return (
          <span className="flex">
            <Rating
              precision={0.5}
              name="read-only"
              value={hospital[column.accessor]}
              readOnly
            />
          </span>
        );

      case "status":
        return (
          <span
            style={{
              backgroundColor:
                hospital[column.accessor] === "open"
                  ? "#4CAF50"
                  : hospital[column.accessor] === "good"
                  ? "#3B82F6"
                  : hospital[column.accessor] === "close"
                  ? "#f44336"
                  : hospital[column.accessor] === "pending"
                  ? "#FFD700"
                  : "#f44336",
              color: "white",
              padding: "5px 10px",
              borderRadius: "20px",
              display: "inline-block",
              textTransform: "uppercase",
            }}
          >
            {hospital[column.accessor] ?? "N/A"}
          </span>
        );

      default:
        return <span>{hospital[column.accessor] ?? "N/A"}</span>;
    }
  }

  const columns: ProductTableColumn[] = [
    { header: "Hospital", accessor: "hospital_name" },
    { header: "Product", accessor: "b_type" },
    { header: "Quantity", accessor: "qty" },
    { header: "Date/Time", accessor: "tym", className: "" },
    {
      header: "Cancelled Date/Time",
      accessor: "cancel_tym",
      className: "",
    },
    { header: "Reason", accessor: "reason" },
  ];
  const complaintColumns: ProductTableColumn[] = [
    { header: "Hospital", accessor: "name" },
    { header: "Assigned", accessor: "assign" },
    { header: "Product", accessor: "title" },
    { header: "Status", accessor: "status" },
    { header: "Date", accessor: "tym", className: "text-nowrap" },
    { header: "Subject", accessor: "description", className: "max-w-32 w-32" },
    { header: "Reason", accessor: "reason", className: "max-w-32 w-32" },
  ];

  const ratingColumns: ProductTableColumn[] = [
    { header: "Hospital", accessor: "name" },
    { header: "Product", accessor: "product" },
    { header: "Rating", accessor: "rate" },
    { header: "Order Date/Time", accessor: "order_date", className: "" },
    {
      header: "Completed Date/Time",
      accessor: "complete_tym",
      className: "",
    },
    { header: "Feedback", accessor: "feedback" },
  ];
  const noteColumns: ProductTableColumn[] = [
    { header: "Hospital", accessor: "name" },
    { header: "Agent", accessor: "agent_username" },

    { header: "Date/Time", accessor: "date", className: "" },

    { header: "Feedback", accessor: "event" },
  ];

  useEffect(() => {
    const pageFromUrl = parseInt(searchParams.get("page") || "1", 10);
    if (pageFromUrl !== currentPage) {
      setCurrentPage(pageFromUrl);
    }
  }, [location.search, searchParams, currentPage]);

  const goToPage = useCallback(
    (page: number) => {
      applyFilters(
        {
          page,
        },
        navigate,
        pathname
      );
      setCurrentPage(page);
    },
    [navigate, pathname]
  );

  const handleHistoryChange = (history: string) => {
    if (history === "rating") {
      setHistory((prev) => ({
        ...prev,
        note: false,
        complaint: false,
        cancelled: false,
        rating: true,
      }));
      queryClient.refetchQueries({ queryKey: ["rating"] });
    }
    if (history === "complaint") {
      setHistory((prev) => ({
        ...prev,
        note: false,
        complaint: true,
        cancelled: false,
        rating: false,
      }));

      queryClient.refetchQueries({ queryKey: [filters.hospital_id] });
    }
    if (history === "cancelled") {
      setHistory((prev) => ({
        ...prev,
        note: false,
        cancelled: true,
        complaint: false,
        rating: false,
      }));

      queryClient.refetchQueries({ queryKey: ["cancelled-order"] });
    }
    if (history === "note") {
      setHistory((prev) => ({
        ...prev,
        note: true,
        cancelled: false,
        complaint: false,
        rating: false,
      }));

      queryClient.refetchQueries({ queryKey: [noteFilters.hospitalid] });
    }
  };

  return (
    <React.Fragment>
      <Header
        title={
          history.cancelled
            ? "Cancelled History"
            : history.complaint
            ? "Complaint History"
            : history.rating
            ? "Rating History"
            : "History Note"
        }
      />

      {isPending || ratingIsPending || isPendingNote || complaintsIsPending ? (
        <div className="fixed top-0 bottom-0 left-0 right-0 flex mx-auto items-center justify-center h-dvh z-30">
          <div className="bg-gray-700 absolute inset-0 opacity-50"></div>
          <SyncLoader
            color="#3B82F6"
            loading={
              isPending ||
              ratingIsPending ||
              isPendingNote ||
              complaintsIsPending
            }
            className="relative"
            size={30}
          />
        </div>
      ) : (
        <div className="hospitals-container">
          <div className="hospitals-container-top">
            {/* <SearchQuery
              name="search"
              placeholder="Search"
              value={search}
              onChange={(e: any) => setSearch(e.currentTarget.value)}
            /> */}
            <div className="flex flex-wrap items-center gap-3">
              <Button
                onClick={() => handleHistoryChange("cancelled")}
                className={cn(
                  "hover:!bg-red-400",
                  history.cancelled ? "bg-red-500" : "bg-black"
                )}
              >
                Cancelled History
              </Button>
              <Button
                onClick={() => handleHistoryChange("complaint")}
                className={cn(
                  "hover:!bg-green-400",
                  history.complaint ? "bg-green-500" : "bg-black"
                )}
              >
                Complaint History
              </Button>
              <Button
                onClick={() => handleHistoryChange("rating")}
                className={cn(
                  "hover:!bg-yellow-400",
                  history.rating ? "bg-yellow-500" : "bg-black"
                )}
              >
                Rating History
              </Button>
              <Button
                onClick={() => handleHistoryChange("note")}
                className={cn(
                  "hover:!bg-blue-400",
                  history.note ? "bg-blue-500" : "bg-black"
                )}
              >
                History Note
              </Button>
            </div>

            <div className="right-options">
              {/* <ReactSelect
                placeholder="Filter by Status"
                // defaultValue={status}
                options={filterStatus?.map(
                  (status: { value: string; label: string }) => ({
                    value: status.value,
                    label: status.label,
                  })
                )}
                onChange={handleStatusChange}
              /> */}
            </div>
          </div>

          <div className="hospitals-container-bottom table-responsive mb-5">
            <>
              {history.cancelled && (
                <ProductTable
                  data={cancelledOrder ?? []}
                  columns={columns}
                  renderCell={renderCell}
                  totalPages={paginate?.totalPages}
                  currentPage={currentPage}
                  goToPage={goToPage}
                  noDataMessage={
                    "You Currently don't have any Cancelled History"
                  }
                />
              )}
              {history.rating && (
                <ProductTable
                  data={rating ?? []}
                  columns={ratingColumns}
                  renderCell={renderCell}
                  totalPages={paginateRating?.totalPages}
                  currentPage={currentPage}
                  goToPage={goToPage}
                  noDataMessage={"You Currently don't have any Rating History"}
                />
              )}
              {history.complaint && (
                <ProductTable
                  data={complaints ?? []}
                  columns={complaintColumns}
                  renderCell={renderCell}
                  totalPages={paginateComplaints?.totalPages}
                  currentPage={currentPage}
                  goToPage={goToPage}
                  noDataMessage={
                    "You Currently don't have any Complaint History"
                  }
                />
              )}
              {history.note && (
                <ProductTable
                  data={feed ?? []}
                  columns={noteColumns}
                  renderCell={renderCell}
                  totalPages={paginatingNote?.totalPages}
                  currentPage={currentPage}
                  goToPage={goToPage}
                  noDataMessage={"You Currently don't have any Note History"}
                />
              )}
            </>
          </div>
        </div>
      )}
    </React.Fragment>
  );
};

export default HospitalHistory;
