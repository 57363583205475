/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback } from "react";

import { FaHospital } from "react-icons/fa";

import ExodusArena from "../../../../../components/focuscards/exodusArena";
// import Reminders from "../../../../../components/focuscards/reminders";

import LoadingButton from "../../../../../components/common/loadingButton";
import { Link } from "react-router-dom";

import {
  useGetDashboard,
  useGetHospitalsCount,
  useGetTargetRevenue,
} from "../../../../../hooks/useDashboard";
import { useGlobalContext } from "../../../../../contexts/globalContext";

import DashboardCard from "./DashboardCard";
import ActivityFeed from "../../activities/activityFeed";
import ChurnInfoCard from "../../churn/ChurnInfoCard";
import { useGetChurnOrderCount } from "../../../../../hooks/useExodus";
import { getPrivileges } from "../../../../../services/authService";
import useGetDateRange from "../../../../../hooks/dates/use-get-date-range";
import HealthCard from "../../health-score/HealthCard";

export const Focus = () => {
  const privileges = getPrivileges();
  const { user, activeTab } = useGlobalContext();

  const { dashboard, isPending, isError } = useGetDashboard(user?.id as string);
  const { target } = useGetTargetRevenue(user?.id as string);

  const { count } = useGetHospitalsCount(user?.id as string);

  const getUserId = useCallback(
    (user: any) => {
      if (!privileges.createAgent) {
        return user;
      }
      return "";
    },
    [privileges.createAgent]
  );

  const { getSeconds } = useGetDateRange("full");

  const newFullRange = getSeconds().readable;

  const { getSeconds: riskMilliseconds } = useGetDateRange("risk");
  const newRiskRange = riskMilliseconds().readable;

  const { getSeconds: exitMilliseconds } = useGetDateRange("exit");
  const newExitRange = exitMilliseconds().readable;

  const filters = {
    user_id: getUserId(user?.id) as string,
    status: "churn",
  };
  const { churnCount: fullCount } = useGetChurnOrderCount({
    ...filters,
    date_range: newFullRange,
  });
  const { churnCount: partialCount } = useGetChurnOrderCount({
    ...filters,
    churnstatus: "not_null",
    date_range: newFullRange,
  });
  const { churnCount: riskCount } = useGetChurnOrderCount({
    ...filters,
    date_range: newRiskRange,
  });
  const { churnCount: existCount } = useGetChurnOrderCount({
    ...filters,
    date_range: newExitRange,
  });

  // const [selectedType] = useState<{
  //   full: boolean;
  //   partial: boolean;
  //   risk: boolean;
  //   exit: boolean;
  // }>({
  //   full: true,
  //   partial: true,
  //   risk: true,
  //   exit: true,
  // });

  // useEffect(() => {
  //   if (selectedType) {
  //     const today = new Date();
  //     let start = new Date();
  //     let end = new Date();

  //     if (selectedType.full) {
  //       start.setFullYear(today.getFullYear() - 1); // Full is from a year ago
  //       const fullDateRange = `${Math.floor(
  //         start.getTime() / 1000
  //       )},${Math.floor(end.getTime() / 1000)}`;
  //       setFull(fullDateRange);
  //     }

  //     if (selectedType.partial) {
  //       start.setFullYear(today.getFullYear() - 1); // Partial is from a year ago
  //       const partialDateRange = `${Math.floor(
  //         start.getTime() / 1000
  //       )},${Math.floor(end.getTime() / 1000)}`;
  //       setPartial(partialDateRange);
  //     }

  //     if (selectedType.risk) {
  //       let startRisk = new Date(today); // Clone today's date
  //       startRisk.setMonth(today.getMonth() - 3);

  //       // Handle year rollover if the month subtraction goes to the previous year
  //       if (startRisk.getMonth() > today.getMonth()) {
  //         startRisk.setFullYear(today.getFullYear() - 1);
  //       }

  //       const riskDateRange = `${Math.floor(
  //         startRisk.getTime() / 1000
  //       )},${Math.floor(end.getTime() / 1000)}`;
  //       setRisk(riskDateRange);
  //     }

  //     if (selectedType.exit) {
  //       start.setFullYear(today.getFullYear() - 2); // Exit is from 2 years ago
  //       const exitDateRange = `${Math.floor(
  //         start.getTime() / 1000
  //       )},${Math.floor(end.getTime() / 1000)}`;
  //       setExit(exitDateRange);
  //     }
  //   }
  // }, [selectedType]);

  if (isPending)
    return (
      <div className="flex flex-col  justify-between items-center w-full p-5 sm:p-10 h-screen">
        <LoadingButton
          visible={isPending}
          message="Fetching Data... Please wait"
        />
      </div>
    );

  if (isError)
    return (
      <div className="flex flex-col sm:flex-row justify-between items-center w-full p-5 sm:p-10">
        <div className="flex flex-col justify-center items-center gap-5 sm:gap-10 mx-5 sm:mx-10">
          <h1 className="text-4xl text-red-500">Error Fetching Data</h1>
        </div>
      </div>
    );
  const notActive =
    Number(dashboard?.hospital ?? 0) - Number(dashboard?.active ?? 0);

  const cardData = [
    {
      title: "Active Hospitals",
      value: dashboard?.active ?? 0,
      icon: <FaHospital className="text-white w-6 h-6" />,
    },
    {
      title: "Non-active Hospitals",
      // title: "Number of Hospitals in Exodus",
      value: notActive,
      icon: <FaHospital className="text-white w-6 h-6" />,
    },
    {
      title: "Active Hospital Target",
      value: target?.active_hospitals ?? 0,
      icon: <FaHospital className="text-white w-6 h-6" />,
    },
    {
      title: "Actual Hospitals",
      value: dashboard?.hospital ?? 0,
      icon: <FaHospital className="text-white w-6 h-6" />,
    },
  ];

  return (
    <div className="flex flex-col w-full mb-10">
      <div className="flex flex-col lg:flex-row justify-center lg:justify-between items-center w-full">
        <div
          className={
            activeTab !== "focus"
              ? "flex flex-col-reverse lg:flex-row justify-between w-full pr-1 gap-x-2"
              : "flex lg:flex-row flex-col justify-between w-full pr-1 gap-x-2"
          }
        >
          {activeTab === "focus" ? (
            <div className="lg:flex justify-center xl:justify-start lg:w-full">
              <div className="grid md:grid-cols-2 gap-3 mt-3 w-full">
                {cardData.map((card, index) => (
                  <div key={index} className="h-full">
                    <DashboardCard {...card} />
                  </div>
                ))}
              </div>
            </div>
          ) : (
            <div className="w-full">
              <ActivityFeed pageSize={3} />
            </div>
          )}

          <div
            className={`lg:-mt-52 ${
              window.innerWidth <= 640 ? "mt-0" : ""
            } lg:ml-auto`}
          >
            <div
              className={`hidden lg:flex justify-center flex-col items-center gap-4 border-4 border-white p-4 rounded-lg mt-4 sm:mt-0 sm:flex-row ${
                window.innerWidth <= 640 ? "bg-purple-900" : ""
              }`}
            >
              <div className="border-t-4 border-white w-10 "></div>
              <p className="lg:text-white text-center lg:text-3xl font-bold">
                Your Hospitals &gt;&gt;
                <Link
                  to="/hospitals"
                  className="lg:text-white lg:text-3xl font-bold italic underline underline-offset-4 px-3"
                >
                  {" "}
                  {count ?? 0}
                </Link>
              </p>
            </div>
            <div className="border-2 border-purple-900 bg-gray-100 p-4 rounded-lg max-w-full lg:max-w-2xl mt-2 sm:mt-0">
              <div className="flex flex-col gap-3">
                {dashboard && (
                  <>
                    <ExodusArena exodus={dashboard?.exodus ?? 0} />
                    <ChurnInfoCard
                      full={fullCount}
                      partial={partialCount}
                      risk={riskCount}
                      exit={existCount}
                    />{" "}
                    <HealthCard
                      preChurn={fullCount}
                      danger={partialCount}
                      addValue={riskCount}
                      checkIn={existCount}
                      upsell={existCount}
                    />
                    {/* <Reminders
                      pitch={dashboard?.pitch ? dashboard?.pitch : 0}
                      close={dashboard?.close ? dashboard?.close : 0}
                    /> */}
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
