import React, { Dispatch, SetStateAction, useEffect, useState } from "react";

import { useForm } from "react-hook-form";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import * as Dialog from "@radix-ui/react-dialog";
import { useRequestExpense } from "../../hooks/logistics/useLogistics";
import axios from "axios";
import { Switch } from "../../@/components/ui/switch";
import utilities from "../../utils/utilities";
import { useGlobalContext } from "../../contexts/globalContext";
import { useNavigate } from "react-router-dom";
import { Button } from "../../@/components/ui/button";
import { useUpdateHospitalAddress } from "../../hooks/useHospital";

const formatGeoCode = (geocode: string) => {
  const parts = geocode.replace(/[NE]/g, "").split(",");
  const lat = parseFloat(parts[0].trim());
  const lng = parseFloat(parts[1].trim());
  return `[${lat},${lng}]`;
};

const logisticSchema = z.object({
  start: z.string().min(1, "Start Address is required"),
  destination: z
    .string({ message: "Hospital Address is required" })
    .min(1, "Hospital Address is required"),
  amountOrKilometers: z
    .number({ invalid_type_error: "This field must be a number" })
    .positive("Value must be a positive number"),
  date_incured: z
    .string()
    .refine((value) => value !== "", { message: "Date is required" }),
  // note: z.string().optional(),
});

type LogisticFormData = z.infer<typeof logisticSchema>;
export default function LogExpenses({
  destinationAddress,
  destinationGeoCode,
  createExpense,
  setCreateExpense,
  note,
  hospital_id,
}: {
  destinationAddress: string;
  destinationGeoCode: string;
  createExpense: boolean;
  setCreateExpense: Dispatch<SetStateAction<boolean>>;
  note: string;
  hospital_id: string;
}) {
  const navigate = useNavigate();
  const { user } = useGlobalContext();
  const [addCurrentAddress, setAddCurrentAddress] = useState(false);
  const [isDriving, setIsDriving] = useState(false);

  const { mutate, isPending: isSubmitting } = useRequestExpense();
  const { mutateUpdateHospitalAddress } = useUpdateHospitalAddress();
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
    getValues,
  } = useForm<LogisticFormData>({
    resolver: zodResolver(logisticSchema),
  });

  useEffect(() => {
    setValue("destination", destinationAddress);
  }, [destinationAddress, setValue]);

  const API_KEY = "AIzaSyDQjd9fzSdMQzaCXmjXMlfJILG53ZVJs5M";

  // Geocode function to get latitude and longitude
  const geoCodeAddress = async (address: string) => {
    try {
      const response = await axios.get(
        `https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURIComponent(
          address
        )}&key=${API_KEY}`
      );
      const location = response.data.results[0]?.geometry.location;
      return location ? { lat: location.lat, lng: location.lng } : null;
    } catch (error) {
      console.error("Error fetching geocode", error);
      return null;
    }
  };

  const onSubmit = async (data: LogisticFormData) => {
    const startGeoCode = await geoCodeAddress(data.start);
    // const destinationGeoCode = await geoCodeAddress(data.destination);
    const formattedStartGeoCode =
      startGeoCode?.lat && startGeoCode?.lng
        ? `[${startGeoCode.lat},${startGeoCode.lng}]`
        : null;

    const formattedDestinationGeoCode = formatGeoCode(destinationGeoCode);

    const date = utilities.formatDate(data.date_incured, "YYYY-MM-DD HH:mm:ss");

    const payload = {
      userid: user?.id,
      start: data.start,
      start_geocode: formattedStartGeoCode || "",
      destination: getValues("destination"),
      destination_geocode: formattedDestinationGeoCode || null,
      estimated_distance: isDriving ? data.amountOrKilometers : null,
      estimated_cost: !isDriving ? data.amountOrKilometers : null,
      note: note,
      date_incured: date,
    };

    mutate(payload, {
      onSuccess() {
        if (addCurrentAddress && payload.destination !== "") {
          mutateUpdateHospitalAddress(
            { address: getValues("destination"), hospital_id: hospital_id },
            {
              onSuccess() {
                setCreateExpense(false);
                navigate("/logistic-tracker");
              },
            }
          );
          return;
        }
        setCreateExpense(false);
        navigate("/logistic-tracker");
      },
    });
  };
  return (
    <div>
      {/* Estimate Form */}
      <Dialog.Root open={createExpense} onOpenChange={setCreateExpense}>
        <Dialog.Portal>
          <Dialog.Overlay className="data-[state=open]:animate-overlayShow fixed inset-0 w-full h-full bg-black opacity-40" />
          <Dialog.Content
            aria-describedby={undefined}
            className="fixed top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%] w-full max-w-lg mx-auto px-4"
          >
            <div className="bg-white rounded-md shadow-lg px-4 py-6">
              <div className="flex items-center justify-end">
                <Dialog.Close
                  className="p-2 text-gray-400 rounded-md hover:bg-gray-100"
                  onClick={() => {}}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="w-5 h-5 mx-auto"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                  >
                    <path
                      fillRule="evenodd"
                      d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                      clipRule="evenodd"
                    />
                  </svg>
                </Dialog.Close>
              </div>
              <div className="max-w-sm mx-auto space-y-3 text-start ">
                <Dialog.Title className="text-lg font-medium text-gray-800 flex items-center flex-wrap">
                  <span className="text-nowrap font-semibold mr-2">
                    Your Current Location:
                  </span>
                  {destinationAddress ?? (
                    <>
                      <Button
                        className="bg-black"
                        variant={"default"}
                        onClick={() => setAddCurrentAddress(true)}
                      >
                        Add Hospital Address
                      </Button>
                      {errors.destination && (
                        <p className="text-red-500 text-sm mt-1">
                          {errors.destination.message}
                        </p>
                      )}
                    </>
                  )}
                </Dialog.Title>
                <div className="flex items-center justify-center p-4">
                  <form onSubmit={handleSubmit(onSubmit)} className="space-y-4">
                    <div className="flex flex-col gap-3">
                      <div>
                        <input
                          type="text"
                          {...register("start")}
                          placeholder="Where did you start the journey from?"
                          className={`w-full p-3 border ${
                            errors.start ? "border-red-500" : "border-gray-300"
                          } rounded-md focus:ring-2 focus:ring-indigo-500`}
                        />
                        {errors.start && (
                          <p className="text-red-500 text-sm mt-1">
                            {errors.start.message}
                          </p>
                        )}
                      </div>
                      {addCurrentAddress && (
                        <div>
                          <input
                            type="text"
                            {...register("destination")}
                            placeholder="What is the hospital address?"
                            className={`w-full p-3 border ${
                              errors.destination
                                ? "border-red-500"
                                : "border-gray-300"
                            } rounded-md focus:ring-2 focus:ring-indigo-500`}
                          />
                          {errors.destination && (
                            <p className="text-red-500 text-sm mt-1">
                              {errors.destination.message}
                            </p>
                          )}
                        </div>
                      )}
                    </div>

                    <div className="flex items-center mb-4 gap-2">
                      <Switch
                        checked={isDriving}
                        onCheckedChange={setIsDriving}
                      />
                      <label
                        htmlFor="isDriving"
                        className="mr-2 font-medium text-gray-700"
                      >
                        {isDriving
                          ? "Driving - Estimated Distance (Km)"
                          : "Estimated Cost"}
                      </label>
                    </div>

                    <div className="flex flex-col md:flex-row gap-2">
                      <div className="">
                        <input
                          type="number"
                          {...register("amountOrKilometers", {
                            valueAsNumber: true,
                          })}
                          placeholder={
                            isDriving ? "Kilometers Covered" : "Amount Spent"
                          }
                          className={`w-full p-3 border ${
                            errors.amountOrKilometers
                              ? "border-red-500"
                              : "border-gray-300"
                          } rounded-md focus:ring-2 focus:ring-indigo-500`}
                        />
                        {errors.amountOrKilometers && (
                          <p className="text-red-500 text-sm mt-1">
                            {errors.amountOrKilometers.message}
                          </p>
                        )}
                      </div>
                      {/* <div>
                      <input
                        type="text"
                        value={totalCost}
                        readOnly
                        placeholder="Total Cost"
                        className="w-full p-3 border border-gray-300 rounded-md bg-gray-100"
                      />
                    </div> */}
                      <div className="">
                        <input
                          {...register("date_incured", {
                            value: new Date().toISOString().split("T")[0],
                          })}
                          type="date"
                          placeholder="Date Incured"
                          className="w-full p-3 border border-gray-300 rounded-md bg-gray-100"
                        />{" "}
                        {errors.date_incured && (
                          <p className="text-red-500 text-sm mt-1">
                            {errors.date_incured.message}
                          </p>
                        )}
                      </div>
                    </div>
                    {/* <div>
                      <textarea
                        {...register("note")}
                        placeholder="Feedback and Contact Info"
                        className="w-full p-3 border border-gray-300 rounded-md focus:ring-2 focus:ring-indigo-500"
                      />
                      <p className="italic text-xs opacity-80 text-start">
                        Provide feedback on your experience at the location and
                        also provide contact information
                      </p>
                    </div> */}
                    <button
                      disabled={isSubmitting}
                      type="submit"
                      className="w-full p-3 bg-blue-600 text-white rounded-md shadow-md transition-all"
                    >
                      {isSubmitting ? "Submitting..." : "Submit"}
                    </button>
                  </form>
                </div>
              </div>
            </div>
          </Dialog.Content>
        </Dialog.Portal>
      </Dialog.Root>
    </div>
  );
}
