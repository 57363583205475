import React, { Key, useCallback } from "react";
import ExodusHeader from "../../../../components/exodusComponent/ExodusHeader";
import { useGetAllExodus } from "../../../../hooks/useExodus";
import { useGlobalContext } from "../../../../contexts/globalContext";
import { useSearchParams } from "react-router-dom";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../../../../@/components/ui/select";
import { SyncLoader } from "react-spinners";
// import SearchQuery from "../../../../components/forms/searchQuery";
import { Label } from "../../../../@/components/ui/label";
import CustomPagination from "../../../../components/common/pagination/Pagination";

import { getPrivileges } from "../../../../services/authService";
import DateCell from "../../../../components/DateCell";

const HospitalActivities = () => {
  const privileges = getPrivileges();
  const { user } = useGlobalContext();

  const [searchParams, setSearchParams] = useSearchParams();

  const id = searchParams.get("id") || "";
  const type = searchParams.get("type") || "";
  const searchQuery = searchParams.get("search") || "";
  const page = searchParams.get("page") || "1";
  const status = searchParams.get("status") || "exodus";
  const getUserId = useCallback(
    (user: any) => {
      if (!privileges.createAgent) {
        return user;
      }
      return "";
    },
    [privileges.createAgent]
  );
  const filters = {
    user_id: getUserId(user?.id) as string,
    status: status,
    order_by: id,
    type: type,
    limit: 5,
    name: searchQuery,
    page: page,
  };
  const { allExodus, paginate, isPending } = useGetAllExodus(
    { ...filters },
    "activities"
  );

  const activities = [
    {
      label: "Oxygen",
      value: "oxygen",
      title: "Oxygen Activities",
      color: "#5286c3",
      items: allExodus
        ? allExodus
            .map(
              (item: {
                id: string;
                order_by: string;
                payment: string;
                qty: string;
                tym: string;
                urgency: string;
                product: string;
                unitprice: string;
                paymentdetails: string;
                schedule_date: string;
                schedule_time: string;
                order_state: string;
                complete_tym: string;
                created_at: string;
                hospital_name: string;
                cylinder: string;
              }) => {
                const formatDate = (
                  dateString: string | number
                ): JSX.Element => {
                  return (
                    <DateCell dateString={dateString} format="datetime-12" />
                  );
                };
                return {
                  id: item.id,
                  order_by: item.order_by,
                  payment: item.payment,
                  qty: item.qty,
                  tym: item.tym,
                  urgency: item.urgency,
                  product: item.product,
                  unitprice: item.unitprice,
                  paymentdetails: item.paymentdetails,
                  schedule_date: item.schedule_date,
                  schedule_time: item.schedule_time,
                  order_state: item.order_state,
                  complete_tym: item.complete_tym,
                  created_at: formatDate(item.tym),
                  hospital_name: item.hospital_name,
                  cylinder: item.cylinder,
                };
              }
            )
            .filter((item: { id: null }) => item.id !== null)
        : [],
    },

    {
      label: "Blood",
      value: "blood",
      title: "Blood Activities",
      color: "#b70303",
      items: allExodus
        ? allExodus
            .map((item: { [x: string | number]: string | number }) => {
              const formatDate = (dateString: string | number): JSX.Element => {
                return (
                  <DateCell dateString={dateString} format="datetime-12" />
                );
              };

              return {
                id: item.order_id,
                cylinder: item.b_type,
                urgency: item.Request_Type,
                payment: item.payment_type,
                order_by: item.order_by,
                qty: item.qty,
                tym: item.tym,
                product: item.product,
                unitprice: item.unitprice,
                paymentdetails: item.paymentdetails,
                schedule_date: item.schedule_date,
                schedule_time: item.schedule_time,
                order_state: item.order_state,
                complete_tym: item.complete_tym,
                created_at: formatDate(item.tym),
                hospital_name: item.hospital_name,
              };
            })
            .filter((item: { id: null }) => item.id !== null)
        : [],
    },
    {
      label: "Quip",
      value: "quip",
      title: "Quip Activities",
      color: "#F16C03",
      items: allExodus
        ? allExodus
            .map(
              (item: {
                id: string;
                order_by: string;
                payment: string;
                qty: string;
                tym: string;
                urgency: string;
                product: string;
                unitprice: string;
                paymentdetails: string;
                schedule_date: string;
                schedule_time: string;
                order_state: string;
                complete_tym: string;
                created_at: string;
                hospital_name: string;
                b_type: string;
              }) => ({
                id: item.id,
                order_by: item.order_by,
                payment: item.payment,
                qty: item.qty,
                tym: item.tym,
                urgency: item.urgency,
                product: item.product,
                unitprice: item.unitprice,
                paymentdetails: item.paymentdetails,
                schedule_date: item.schedule_date,
                schedule_time: item.schedule_time,
                order_state: item.order_state,
                complete_tym: item.complete_tym,
                created_at: item.created_at,
                hospital_name: item.hospital_name,
                cylinder: item.b_type,
              })
            )
            .filter((item: { id: null }) => item.id !== null)
        : [],
    },
    {
      label: "StockBank",
      value: "stockbank",
      title: "StockBank Activities",
      color: "#215200",
      items: allExodus
        ? allExodus

            .map((item: { [x: string | number]: string | number }) => {
              const formatDate = (dateString: string | number): JSX.Element => {
                return (
                  <DateCell dateString={dateString} format="datetime-12" />
                );
              };
              return {
                id: item.order_id,
                cylinder: item.b_type,
                urgency: item.Request_Type,
                payment: item.payment,
                order_by: item.order_by,
                qty: item.qty,
                tym: formatDate(item.tym),
                product: item.product,
                unitprice: item.unitprice,
                paymentdetails: item.paymentdetails,
                schedule_date: item.schedule_date,
                schedule_time: item.schedule_time,
                order_state: item.order_state,
                complete_tym: item.complete_tym,
                created_at: formatDate(item.tym),
                hospital_name: item.hospital_name,
              };
            })
            .filter((item: { id: null }) => item.id !== null)
        : [],
    },
  ];

  function handleTypeChange(value: string): void {
    if (searchQuery === "")
      setSearchParams({ page: "1", id, type: value, status });
    else
      setSearchParams({
        page: "1",
        id,
        type: value,
        search: searchQuery,
        status,
      });
  }

  const goToPage = useCallback(
    (page: number) => {
      const pageToString = page.toString();
      if (searchQuery === "")
        setSearchParams({ page: pageToString, id, type, status });
      else
        setSearchParams({
          page: pageToString,
          id,
          type,
          search: searchQuery,
          status,
        });
    },
    [id, searchQuery, setSearchParams, type, status]
  );

  return (
    <div className="flex-grow w-full mb-5">
      {isPending ? (
        <div className="fixed top-0 bottom-0 left-0 right-0 flex mx-auto items-center justify-center h-dvh">
          <div className="bg-gray-700 absolute inset-0 opacity-50"></div>
          <SyncLoader size={30} color="blue" className="relative" />
        </div>
      ) : (
        <>
          <ExodusHeader
            Text={"All Activities"}
            subText={`| Hospitals > ${
              allExodus[0]?.name ?? type
            } -> All Activities`}
          />
          <div className="mt-8 w-11/12 mx-auto flex justify-between bg-[#F7F3FE] p-4">
            <p className="text-2xl font-semibold text-[#000000]">Activities</p>
          </div>
          <div className="w-11/12 mx-auto mt-4">
            <div className="flex justify-end w-full mb-3">
              {/* <SearchQuery
                name="search"
                placeholder="Search"
                value={searchQuery}
                onChange={(e: { currentTarget: { value: string } }) => {
                  const newSearchValue = e.currentTarget.value;
                  setSearchParams({
                    id,
                    type: type,
                    search: newSearchValue,
                  });
                }}
              /> */}
              <div className="flex gap-1 items-center max-w-72 w-full">
                <Label className="w-full font-semibold ml-1">
                  Select Product Type:{" "}
                </Label>
                <Select
                  onValueChange={handleTypeChange}
                  defaultValue={type as string}
                >
                  <SelectTrigger>
                    <SelectValue placeholder="Select Product Type" />
                  </SelectTrigger>
                  <SelectContent position="item-aligned">
                    {activities?.map((activity) => (
                      <SelectItem value={activity.value} key={activity.value}>
                        {activity.label}
                      </SelectItem>
                    ))}
                  </SelectContent>
                </Select>
              </div>
            </div>
            <table className="table-auto text-left w-full">
              <thead className="text-gray-600 font-medium border-b">
                <tr>
                  <th className="py- pr-6">
                    {
                      activities.find((activity) => activity.value === type)
                        ?.title
                    }
                  </th>
                  <th className="py- pr-6">Status</th>
                  <th className="py- pr-6">Time</th>
                  <th className="py- pr-6">Payment</th>
                </tr>
              </thead>
              {activities.find((activity) => activity.value === type)?.items
                ?.length > 0 ? (
                <tbody className="text-gray-600 divide-y">
                  {activities
                    .find((activity) => activity.value === type)
                    ?.items?.map(
                      (
                        item: {
                          payment: string;
                          id: string | number;
                          urgency: string | number;
                          cylinder: string | number;
                          order_state: string | number;
                          created_at: string | number;
                          hospital_name: string;
                        },
                        idx: Key | null | undefined
                      ) => (
                        <tr key={idx}>
                          <td
                            className="whitespace-nowrap"
                            data-label={
                              activities.find(
                                (activity) => activity.value === type
                              )?.title
                            }
                          >
                            <div className="flex flex-col">
                              <p>#{item.id}</p>
                              <p>
                                <span className="capitalize">
                                  {item.urgency}
                                </span>{" "}
                                {item.cylinder}
                              </p>
                            </div>
                          </td>

                          <td
                            className="whitespace-nowrap text-indigo-600"
                            data-label="Status"
                          >
                            {item.order_state}
                          </td>
                          <td className="whitespace-nowrap" data-label="Time">
                            <span
                              className={`rounded-full font-semibold text-xs`}
                            >
                              {item.created_at}
                            </span>
                          </td>
                          <td className="whitespace-nowrap" data-label="Time">
                            <span
                              className={`rounded-full font-semibold text-xs`}
                            >
                              {item.payment}
                            </span>
                          </td>
                        </tr>
                      )
                    )}
                </tbody>
              ) : (
                <tbody>
                  <tr>
                    <td colSpan={5} className="py-4 text-center">
                      No Activity
                    </td>
                  </tr>
                </tbody>
              )}
            </table>
            <CustomPagination
              totalPages={paginate?.totalPages}
              currentPage={+page}
              goToPage={goToPage}
            />
          </div>
        </>
      )}
    </div>
  );
};

export default HospitalActivities;
