import { Dispatch, SetStateAction } from "react";

import { useForm, Controller } from "react-hook-form";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import * as Dialog from "@radix-ui/react-dialog";
import { Button } from "../../@/components/ui/button";
import { Separator } from "../../@/components/ui/separator";
import ReactSelect from "../common/ReactSelect";
import { Check } from "lucide-react";

const tier = [
  {
    value: "Tier 1 Partner",
    label: "Tier 1 Partner",
  },
  {
    value: "Tier 2 Partner",
    label: "Tier 2 Partner",
  },
  {
    value: "Tier 3 Partner",
    label: "Tier 3 Partner",
  },
];

const sla = [
  {
    id: "yes",
    label: "Yes",
  },
  {
    id: "no",
    label: "No",
  },
];

const partnerSchema = z.object({
  partner: z.string().min(1, "Partner Tier is required"),
  name: z
    .string({ message: "Beneficiary name is required" })
    .min(3, "Beneficiary name should be at least 3 characters"),
  role: z
    .string({ message: "Beneficiary Job Role is required" })
    .min(3, "Beneficiary Job Role should be at least 3 characters"),
  phone: z
    .string()
    .min(1, { message: "Phone number is required." })
    .regex(/^\+\d{1,4}[6-9]\d{9}$/, {
      message:
        "Invalid phone number. Must include country code with '+' and be followed by 10 digits starting with either 6, 7, 8, or 9.",
    }),
  email: z
    .string()
    .email({ message: "Invalid email address" })
    .min(1, { message: "Email is required." }),
  sla: z.string().min(1, "You have to select an option."),
});

type partnerFormData = z.infer<typeof partnerSchema>;
export default function AddToPartnerProgram({
  addPartner,
  setAddPartner,
  beneficiary,
}: {
  addPartner: boolean;
  setAddPartner: Dispatch<SetStateAction<boolean>>;
  beneficiary: {
    tier: string;
    name: string;
    role: string;
    phone: string;
    email: string;
    sla: string;
  } | null;
}) {
  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<partnerFormData>({
    resolver: zodResolver(partnerSchema),
    defaultValues: {
      partner: beneficiary?.tier ?? "",
      name: beneficiary?.name ?? "",
      role: beneficiary?.role ?? "",
      phone: beneficiary?.phone ?? "",
      email: beneficiary?.email ?? "",
      sla: beneficiary?.sla ?? "",
    },
  });

  const onSubmit = async (data: partnerFormData) => {
    console.log(data);
  };
  return (
    <div>
      <Dialog.Root open={addPartner} onOpenChange={setAddPartner}>
        <Dialog.Portal>
          <Dialog.Overlay className="data-[state=open]:animate-overlayShow fixed inset-0 w-full h-full bg-black opacity-40" />
          <Dialog.Content
            aria-describedby={undefined}
            className="fixed top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%] w-full max-w-xl mx-auto px-1"
          >
            <div className="bg-white rounded-md shadow-lg py-3">
              <div className="flex items-center justify-between px-4">
                <Dialog.Title className="text-lg font-medium text-gray-800 flex items-center flex-wrap">
                  <span className="text-nowrap font-semibold mr-2">
                    {beneficiary
                      ? "Update Program Beneficiary"
                      : "Add to Partner Program"}
                  </span>
                </Dialog.Title>
                <Dialog.Close
                  className="p-2 text-black rounded-md hover:bg-gray-100"
                  onClick={() => {}}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="w-5 h-5 mx-auto"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                  >
                    <path
                      fillRule="evenodd"
                      d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                      clipRule="evenodd"
                    />
                  </svg>
                </Dialog.Close>
              </div>
              <Separator className="my-2 bg-black" />
              <div className="mx-auto space-y-3 text-start ">
                <div className="flex items-center justify-center p-4 w-full">
                  <form
                    onSubmit={handleSubmit(onSubmit)}
                    className="space-y-4 w-full"
                  >
                    <div>
                      <label htmlFor="partner-tier" className="font-semibold">
                        Select Partner Tier
                      </label>
                      <Controller
                        name="partner"
                        control={control}
                        render={({ field }) => (
                          <ReactSelect
                            defaultValue={field.value}
                            placeholder="Select Partner Tier"
                            options={tier}
                            onChange={(selectedOption) =>
                              field.onChange(selectedOption)
                            }
                          />
                        )}
                      />
                      {errors.partner && (
                        <p className="text-red-500 text-sm mt-1">
                          {errors.partner.message}
                        </p>
                      )}
                    </div>
                    <h2 className="font-semibold">
                      Program Beneficiary Details
                    </h2>
                    <div className="w-full">
                      <label htmlFor="beneficiary-name">Beneficiary Name</label>
                      <input
                        type="text"
                        {...register("name")}
                        className={`w-full p-2 border ${
                          errors.name ? "border-red-500" : "border-gray-300"
                        } rounded-md focus:ring-2 focus:ring-indigo-500`}
                      />
                      {errors.name && (
                        <p className="text-red-500 text-sm mt-1">
                          {errors.name.message}
                        </p>
                      )}
                    </div>

                    <div>
                      <label htmlFor="beneficiary-job-role">
                        Beneficiary Job Role
                      </label>
                      <input
                        type="text"
                        {...register("role")}
                        className={`w-full p-2 border ${
                          errors.role ? "border-red-500" : "border-gray-300"
                        } rounded-md focus:ring-2 focus:ring-indigo-500`}
                      />
                      {errors.role && (
                        <p className="text-red-500 text-sm mt-1">
                          {errors.role.message}
                        </p>
                      )}
                    </div>

                    <div className="flex flex-col md:flex-row gap-2">
                      <div className="w-full">
                        <label htmlFor="beneficiary-phone-number">
                          Beneficiary Phone Number
                        </label>
                        <input
                          {...register("phone")}
                          className={`w-full p-2 border ${
                            errors.phone ? "border-red-500" : "border-gray-300"
                          } rounded-md focus:ring-2 focus:ring-indigo-500`}
                        />
                        {errors.phone && (
                          <p className="text-red-500 text-sm mt-1">
                            {errors.phone.message}
                          </p>
                        )}
                      </div>

                      <div className="w-full">
                        <label htmlFor="beneficiary-email">
                          Beneficiary Email
                        </label>
                        <input
                          {...register("email")}
                          type="email"
                          className="w-full p-2 border border-gray-300 rounded-md"
                        />{" "}
                        {errors.email && (
                          <p className="text-red-500 text-sm mt-1">
                            {errors.email.message}
                          </p>
                        )}
                      </div>
                    </div>

                    <div className="flex items-center gap-4">
                      <label htmlFor="sla" className="font-semibold">
                        SLA Signed?
                      </label>
                      <div>
                        <div className="flex space-x-4">
                          {sla.map((option) => (
                            <label
                              key={option.id}
                              className="flex items-center space-x-2"
                            >
                              <input
                                type="radio"
                                value={option.id}
                                {...register("sla")}
                                className="hidden peer"
                              />

                              <div className="w-7 h-7 bg-gray-200 border-gray-400 peer-checked:bg-blue-600 peer-checked:border-blue-600 rounded-sm transition-colors relative flex items-center justify-center cursor-pointer">
                                <Check className="hidden absolute text-white peer-checked:block" />
                              </div>
                              <span>{option.label}</span>
                            </label>
                          ))}
                        </div>
                        {errors.sla && (
                          <p className="text-red-500 text-sm">
                            {errors.sla.message}
                          </p>
                        )}
                      </div>
                    </div>

                    <Button
                      type="submit"
                      className="w-full p-3 bg-[#49209F] hover:bg-purple-900 text-white rounded-md shadow-md transition-all"
                    >
                      {beneficiary ? "Update" : "Submit"}
                    </Button>
                  </form>
                </div>
              </div>
            </div>
          </Dialog.Content>
        </Dialog.Portal>
      </Dialog.Root>
    </div>
  );
}
