import { useEffect, useState } from "react";

import { useGlobalContext } from "../../../contexts/globalContext";
import { Navigate } from "react-router-dom";
import navigationRoutes from "../../../routes/navigationRoutes";
import authService from "../../../services/authService";
import { setPreExodusCount } from "../../../redux/slices/Pipeline";
import { useDispatch } from "react-redux";
const Logout = () => {
  // navigator.serviceWorker?.getRegistrations()
  // .then(function(registrations) {
  //   for(let registration of registrations) {
  //     registration.unregister();
  //     console.log(registration, "unregistered");
  //   }
  // })
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const { setUser, setCountry } = useGlobalContext();

  useEffect(() => {
    setLoading(true);

    dispatch(setPreExodusCount(""));
    authService.logout();
    setUser(null);
    setCountry(null);
    setLoading(false);
  }, [dispatch, setCountry, setUser]);

  return (
    <div className="login">
      <div className="login-inner">
        <h2 className="login-inner-title">You have been logged out</h2>
        <p className="login-inner-subtitle">
          You have been logged out successfully
        </p>
        {loading ? (
          <p>Logging out...</p>
        ) : (
          <Navigate to={navigationRoutes.selectCountry} />
        )}
      </div>
    </div>
  );
};

export default Logout;

