import { useQuery, useMutation, keepPreviousData } from "@tanstack/react-query";
import exodusServices from "../services/exodus.service";
import { AxiosResponse } from "axios";
import { Info } from "../ts-utils/interfaces";

interface ResponseData {
  baseurl: any;
  data: Info;
  meta: any;
}

export const useGetExodus = (id: string) => {
  const { isError, isSuccess, data, isPending, refetch } = useQuery({
    queryKey: ["exodus", id],
    queryFn: async () => {
      const response = (await exodusServices.exodus(
        id
      )) as AxiosResponse<ResponseData>;
      return response;
    },
    placeholderData: keepPreviousData,
  });

  const exodus: any = data?.data || [];

  return { isError, isSuccess, exodus, isPending, refetch };
};

export const useSingleExodus = (id: number | string) => {
  const { isError, isSuccess, data, isPending } = useQuery({
    queryKey: ["single-exodus", id],
    queryFn: async () => {
      const response = await exodusServices.singleExodus(id);
      return response.data as any;
    },
  });

  const singleExodus: any = data;

  return { isError, isSuccess, singleExodus, isPending };
};

export const useGetDrip = () => {
  const { isError, isSuccess, data, isFetching, isPending } = useQuery({
    queryKey: ["drip"],
    queryFn: async () => {
      const response = await exodusServices.getDrip();
      return response.data as any;
    },
    placeholderData: keepPreviousData,
  });

  const drip: any = data?.data;
  const baseUrl = data?.baseurl;

  return { isError, isSuccess, drip, baseUrl, isFetching, isPending };
};

export const usePostDrip = () => {
  const {
    isError,
    isSuccess,
    mutate: mutatePostDrip,
    isPending,
  } = useMutation({
    mutationKey: ["call-logs"],
    mutationFn: async (data: any) => {
      const response: any = await exodusServices.postDrip(data);
      return response;
    },
  });

  return { isError, isSuccess, mutatePostDrip, isPending };
};
export const useSendNote = () => {
  const {
    isError,
    isSuccess,
    mutate: mutateSendNote,
    isPending,
  } = useMutation({
    mutationKey: ["send-note"],
    mutationFn: async (data: any) => {
      const response: any = await exodusServices.sendNote(data);
      return response;
    },
  });

  return { isError, isSuccess, mutateSendNote, isPending };
};

export const useSendMessage = () => {
  const {
    isError,
    isSuccess,
    mutate: mutateSendMessage,
    isPending,
  } = useMutation({
    mutationKey: ["send-message"],
    mutationFn: async (data: FormData) => {
      const response: any = await exodusServices.sendMessage(data);
      return response;
    },
  });

  return { isError, isSuccess, mutateSendMessage, isPending };
};

export const useGetAllExodus = (
  filters: {
    page?: number | string;
    limit?: number;
    status?: string;
    name?: string;
    user_id?: string;
    channel?: string;
    type?: string;
    order_by?: string;
    list?: string;
    date_range?: string;
    churnstatus?: string;
  },
  type?: string
) => {
  const { isError, isSuccess, data, isPending } = useQuery({
    queryKey: [
      "all-exodus",
      filters.date_range,
      filters.user_id,
      filters.churnstatus,
      filters.page,
      filters.name,
      filters,
    ],
    enabled: !!type,
    queryFn: async () => {
      const response = await exodusServices.getAllExodus(filters);
      return response.data as any;
    },
    placeholderData: keepPreviousData,
  });

  const allExodus: any = data?.data;
  const paginate = data?.meta;

  return { isError, isSuccess, allExodus, isPending, paginate };
};

export const useGetExodusPipeline = (
  filters: {
    page?: number | string;
    limit?: number;
    status?: string;
    name?: string;
    user_id?: string;
    list?: string;
  },
  type: string
) => {
  const { isError, isSuccess, data, isPending } = useQuery({
    queryKey: ["exodus-pipeline", filters, type],
    enabled: !!type,
    queryFn: async () => {
      const response = await exodusServices.exodusPipeline(filters);
      return response.data as any;
    },
    placeholderData: keepPreviousData,
  });

  const exodusPipeline: any = data?.data;
  const meta = data?.meta;

  return { isError, isSuccess, exodusPipeline, isPending, meta };
};

export const useGetChurnOrderCount = (
  filters: {
    page?: number | string;
    limit?: number;
    status?: string;
    name?: string;
    user_id?: string;
    channel?: string;
    type?: string;
    order_by?: string;
    list?: string;
    date_range?: string;
    churnstatus?: string;
  },
  type?: string
) => {
  const { isError, isSuccess, data, isPending } = useQuery({
    queryKey: ["churn-count", filters],
    queryFn: async () => {
      const response = await exodusServices.getChurnOrderCount(filters);
      return response.data as any;
    },
    placeholderData: keepPreviousData,
  });

  const churnCount: any = data?.data;
  const paginate = data?.meta;

  return { isError, isSuccess, churnCount, isPending, paginate };
};
