interface SimilarTableProps {
  similarData: any[];
}

const SimilarTable = ({ similarData }: SimilarTableProps) => {
  return (
    <div className="bg-white rounded-lg shadow overflow-x-auto">
      <div className="min-h-[300px] overflow-y-auto">
        <h1 className="text-center text-2xl font-bold py-4">
          Similar Hospitals
        </h1>
        <table className="min-w-full bg-white">
          <thead className="sticky top-0">
            <tr className="text-center">
              <th className="border-b-2 border-gray-300  text-xs leading-4 font-medium text-gray-700 uppercase tracking-wider">
                Name
              </th>
              <th className="border-b-2 border-gray-300  text-xs leading-4 font-medium text-gray-700 uppercase tracking-wider">
                Address
              </th>
              <th className="border-b-2 border-gray-300 text-xs leading-4 font-medium text-gray-700 uppercase tracking-wider">
                City
              </th>
              <th className="border-b-2 border-gray-300 text-xs leading-4 font-medium text-gray-700 uppercase tracking-wider">
                State
              </th>
            </tr>
          </thead>
          <tbody className="text-center">
            {similarData.map((item, index) => (
              <tr key={index}>
                <td className="border-b border-gray-300 text-sm">
                  {item.name}
                </td>
                <td className="border-b border-gray-300 text-sm">
                  {item.address}
                </td>
                <td className="border-b border-gray-300 text-sm">
                  {item.city}
                </td>
                <td className="border-b border-gray-300 text-sm">
                  {item.state}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default SimilarTable;
