import { useCallback, useMemo, useState } from "react";
import { Loader } from "lucide-react";
import { Button } from "../../../../@/components/ui/button";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "../../../../@/components/ui/table";
import { cn } from "../../../../@/lib/utils";
import { SortOrder } from "../../../../components/common/ProductTable";

export type HistoryTable = {
  [x: string]: string;
  label: string;
  key: string;
};

type StatusFilter =
  | "pending"
  | "successful"
  | "failed"
  | "Pitch"
  | "Prospect"
  | "New"
  | "Exodus";

const statusColors = {
  pending: "bg-yellow-200/50 !border-yellow-800 text-yellow-800",
  successful: "bg-green-200/50 !border-green-800 text-green-800",
  failed: "bg-red-200/50 !border-red-800 text-red-800",
  Pitch: "bg-[#441ab7] text-white",
  Prospect: "bg-[#767601] text-white",
  New: "bg-[#424200] text-white",
  Exodus: "bg-[#663300] text-white",
};

type TableProps<T> = {
  data: T[];
  isLoading: boolean;
  columns: HistoryTable[];
};

export default function RequestTable<T extends HistoryTable>({
  data,
  isLoading,
  columns,
}: TableProps<T>) {
  const transformedData = useMemo(() => {
    if (!data) return [];
    return data.map((item: T) => ({
      ...item,
    }));
  }, [data]);

  const [searchTerm, setSearchTerm] = useState("");
  const [requestTypeFilter, setRequestTypeFilter] = useState("");
  const [statusFilter, setStatusFilter] = useState("");
  const [currentPage, setCurrentPage] = useState(1);

  const [sortColumn, setSortColumn] = useState<string | number | null>(null);
  const [sortOrder, setSortOrder] = useState<SortOrder>("asc");

  const itemsPerPage = 3;

  const filterData = useCallback(
    (data: HistoryTable[], search: string, status: string, type: string) => {
      return data.filter((item) => {
        // const matchesSearch = search
        //   ? item.reward.toLowerCase().includes(search.toLowerCase()) ||
        //     item.amount.toLowerCase().includes(search.toLowerCase())
        //   : true;

        const matchesSearch = search
          ? Object.values(item).some((value) =>
              value.toString().toLowerCase().includes(search.toLowerCase())
            )
          : true;
        const matchesStatus = status ? item.status === status : true;
        const matchesType = type ? item.reward === type : true;
        return matchesSearch && matchesStatus && matchesType;
      });
    },
    []
  );

  //   const sortedData = useMemo(() => {
  //     const sortableItems = [...transformedData];
  //     if (sortConfig !== null) {
  //       sortableItems.sort((a, b) => {
  //         if (a[sortConfig.key] < b[sortConfig.key]) {
  //           return sortConfig.direction === "asc" ? -1 : 1;
  //         }
  //         if (a[sortConfig.key] > b[sortConfig.key]) {
  //           return sortConfig.direction === "asc" ? 1 : -1;
  //         }
  //         return 0;
  //       });
  //     }
  //     return sortableItems;
  //   }, [transformedData, sortConfig]);

  const sortedData = useMemo(() => {
    if (!sortColumn) return transformedData;

    return [...transformedData].sort((a, b) => {
      const aValue = a[sortColumn as keyof T];
      const bValue = b[sortColumn as keyof T];

      // Handle null, undefined, or "N/A" values
      const aIsEmpty =
        aValue === null || aValue === undefined || aValue === "N/A";
      const bIsEmpty =
        bValue === null || bValue === undefined || bValue === "N/A";

      if (aIsEmpty && !bIsEmpty) return 1;
      if (!aIsEmpty && bIsEmpty) return -1;
      if (aIsEmpty && bIsEmpty) return 0;

      // Sort normally if neither value is empty
      if (aValue < bValue) return sortOrder === "asc" ? -1 : 1;
      if (aValue > bValue) return sortOrder === "asc" ? 1 : -1;
      return 0;
    });
  }, [transformedData, sortColumn, sortOrder]);

  const filteredData = useMemo(() => {
    return filterData(sortedData, searchTerm, statusFilter, requestTypeFilter);
  }, [filterData, sortedData, searchTerm, statusFilter, requestTypeFilter]);

  const totalPages = Math.ceil(filteredData.length / itemsPerPage);
  const paginatedData = useMemo(() => {
    const startIndex = (currentPage - 1) * itemsPerPage;
    return filteredData.slice(startIndex, startIndex + itemsPerPage);
  }, [filteredData, currentPage]);

  //   const handleSearch = (event: { target: { value: string } }) => {
  //     setSearchTerm(event.target.value);
  //     setCurrentPage(1);
  //   };

  //   const handleRequestTypeFilter = (type: string) => {
  //     setRequestTypeFilter(type);
  //     setCurrentPage(1);
  //   };

  //   const handleStatusFilter = (status: string) => {
  //     setStatusFilter(status);
  //     setCurrentPage(1);
  //   };

  const handlePageChange = (page: number) => {
    if (page > 0 && page <= totalPages) {
      setCurrentPage(page);
    }
  };

  const handleSort = (key: keyof HistoryTable) => {
    if (sortColumn === key) {
      setSortOrder(sortOrder === "asc" ? "desc" : "asc");
    } else {
      setSortColumn(key);
      setSortOrder("asc");
    }
  };

  return (
    <div className="w-full">
      <div className="bg-white">
        <Table>
          <TableHeader>
            {/* <TableRow>
              <TableHead
                className="bg-[#49209F] text-white rounded-l-md cursor-pointer"
                onClick={() => handleSort("date")}
              >
                Date/Time
              </TableHead>
              <TableHead
                className="bg-[#49209F] text-white cursor-pointer"
                onClick={() => handleSort("reward")}
              >
                Reward type
              </TableHead>
              <TableHead
                className="bg-[#49209F] text-white cursor-pointer"
                onClick={() => handleSort("amount")}
              >
                Amount
              </TableHead>
              <TableHead
                className="bg-[#49209F] text-white cursor-pointer"
                onClick={() => handleSort("beneficiary")}
              >
                Beneficiary
              </TableHead>
              <TableHead
                className="bg-[#49209F] text-white rounded-r-md cursor-pointer"
                onClick={() => handleSort("status")}
              >
                Status
              </TableHead>
            </TableRow> */}

            <TableRow>
              {columns.map((column, index) => (
                <TableHead
                  key={column.key}
                  className={cn(
                    "bg-[#49209F] text-white cursor-pointer",
                    index === 0 && "rounded-l-md",
                    index === columns.length - 1 && "rounded-r-md"
                  )}
                  onClick={() => handleSort(column.key)}
                >
                  {column.label}
                </TableHead>
              ))}
            </TableRow>
          </TableHeader>
          <TableBody>
            {isLoading ? (
              <TableRow className="flex items-center justify-center">
                <TableCell
                  colSpan={5}
                  className="flex items-center justify-center text-center w-full h-40"
                >
                  <div className="text-center text-gray-500 text-lg flex items-center justify-center space-x-2">
                    <Loader className="animate-spin h-6 w-6 text-gray-500" />{" "}
                    <span>Loading...</span>
                  </div>
                </TableCell>
              </TableRow>
            ) : paginatedData.length > 0 ? (
              paginatedData.map((request, index) => (
                <TableRow key={request.id ?? index + 1}>
                  {columns.map((column) => (
                    <TableCell key={column.key}>
                      {column.key === "status" ? (
                        <span
                          className={`px-2 py-1 rounded-full text-xs text-nowrap capitalize border ${
                            statusColors[request[column.key] as StatusFilter]
                          }`}
                        >
                          {request[column.key]}
                        </span>
                      ) : (
                        request[column.key]
                      )}
                    </TableCell>
                  ))}
                </TableRow>
              ))
            ) : (
              <TableRow className="flex items-center justify-center">
                <TableCell
                  colSpan={5}
                  className="flex items-center justify-center text-center w-full h-40"
                >
                  <div className="relative text-center text-gray-500 text-lg flex items-center justify-center space-x-2">
                    <span className="absolute w-full text-nowrap inset-x-32">
                      No Requests at this time
                    </span>
                  </div>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
        <div className="flex justify-between items-center mt-4 space-x-2">
          <Button
            onClick={() => handlePageChange(currentPage - 1)}
            disabled={currentPage === 1}
          >
            Previous
          </Button>
          <span>
            Page {currentPage} of {totalPages}
          </span>
          <Button
            onClick={() => handlePageChange(currentPage + 1)}
            disabled={currentPage === totalPages}
          >
            Next
          </Button>
        </div>
        {/* Pagination Controls */}
        {/* {paginatedData.length > 3 && (
          <div className="flex justify-between items-center mt-4 space-x-2">
            <Button
              onClick={() => handlePageChange(currentPage - 1)}
              disabled={currentPage === 1}
            >
              Previous
            </Button>
            <span>
              Page {currentPage} of {totalPages}
            </span>
            <Button
              onClick={() => handlePageChange(currentPage + 1)}
              disabled={currentPage === totalPages}
            >
              Next
            </Button>
          </div>
        )} */}
      </div>
    </div>
  );
}
