/* eslint-disable react-hooks/exhaustive-deps */
import { Key, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Header from "../../../../components/blocks/header";
import utilities from "../../../../utils/utilities";
import { Button } from "../../../../@/components/ui/button";
import {
  useGetQuotationsDetails,
  useDismissQuotation,
} from "../../../../hooks/purchasingOrder/usePurchaseOrder";
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
} from "../../../../@/components/ui/alert-dialog";
import * as Dialog from "@radix-ui/react-dialog";
import PaymentMethods from "../purchasingOrder/PaymentMethod";
import { useDispatch, useSelector } from "react-redux";
import { setData, setPaymentMethod } from "../../../../redux/slices/Quotations";
import { RootState } from "../../../../redux/rootReducer";
import toastService from "../../../../services/toastService";
import { Link } from "react-router-dom";
const QuotationDetails = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id, status } = useParams();
  const { quotations } = useGetQuotationsDetails(id as string);
  const quotationType = useSelector(
    (state: RootState) => state.quotation.quotationType
  );

  dispatch(setData(quotations));
  const { mutateDismiss } = useDismissQuotation();

  const [paymentType, setPaymentType] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const [isProceed, setIsProceed] = useState(false);

  const dismissQuotation = () => {
    const payload = {
      quote_id: quotations[0].quote_id,
      stockOrQuip: quotationType,
    };
    mutateDismiss(payload, {
      onSuccess: (data) => {
        toastService.success(data.data.message);
        setIsOpen(false);
        navigate(`/quotations`);
      },
    });
  };

  function handlePaymentType(paymentType: any): void {
    setPaymentType(paymentType);
    dispatch(setPaymentMethod(paymentType));
    navigate(`/quotations/order-information`);
  }
  return (
    <div>
      <Header title={`Consumable Quotation Details ID: ${id}`} />
      {/* Dismiss Dialog */}
      <AlertDialog open={isOpen} onOpenChange={setIsOpen}>
        <AlertDialogContent>
          <AlertDialogHeader>
            <AlertDialogTitle>Are you absolutely sure?</AlertDialogTitle>
            <AlertDialogDescription>
              This action cannot be undone. This will permanently dismiss this
              Quotation.
            </AlertDialogDescription>
          </AlertDialogHeader>
          <AlertDialogFooter className="flex items-center">
            <AlertDialogCancel>Cancel</AlertDialogCancel>
            <AlertDialogAction className="mt-1.5" onClick={dismissQuotation}>
              Continue
            </AlertDialogAction>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>

      {/* Payment dialog */}

      <Dialog.Root open={isProceed} onOpenChange={setIsProceed}>
        <Dialog.Portal>
          <Dialog.Overlay className="data-[state=open]:animate-overlayShow fixed inset-0 w-full h-full bg-black opacity-40" />
          <Dialog.Content
            aria-describedby={undefined}
            className="fixed top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%] w-full max-w-2xl px-4"
          >
            <div className="bg-white rounded-md shadow-lg px-4 py-6">
              <div className="flex items-center justify-end">
                <Dialog.Close
                  className="p-2 text-gray-400 rounded-md hover:bg-gray-100"
                  onClick={() => {
                    setIsProceed(false);
                  }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="w-5 h-5 mx-auto"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                  >
                    <path
                      fillRule="evenodd"
                      d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                      clipRule="evenodd"
                    />
                  </svg>
                </Dialog.Close>
              </div>
              <div className="space-y-3 overflow-y-auto max-h-96 p-3">
                <Dialog.Title className="text-lg font-medium text-gray-800 "></Dialog.Title>

                <div className="flex flex-col justify-center gap-3">
                  <PaymentMethods
                    handlePaymentType={handlePaymentType}
                    paymentType={paymentType}
                  />
                </div>
              </div>
              {/* <div className="flex items-center justify-center">
                <Button>Continue</Button>
              </div> */}
            </div>
          </Dialog.Content>
        </Dialog.Portal>
      </Dialog.Root>

      <div className="mb-5">
        <table className="w-full border-collapse border border-gray-300 mt-20">
          <thead className="bg-gray-200 ">
            <tr className="text-center">
              <th className="py-2 px-4 font-semibold ">ItemID</th>
              <th className="py-2 px-4 font-semibold ">Product</th>
              <th className="py-2 px-4 font-semibold ">Price</th>
              <th className="py-2 px-4 font-semibold ">Quantity</th>
            </tr>
          </thead>
          <tbody className="text-center">
            {quotations?.map(
              (item: {
                cost: string;
                id: Key | null | undefined;
                item_id: string | number;
                name: string;
                price: string;
                qty: string | number;
              }) => (
                <tr key={item.id}>
                  <td className="py-2 px-4 border-b border-gray-300">
                    {item.item_id}
                  </td>
                  <td className="py-2 px-4 border-b border-gray-300">
                    {item.name}
                  </td>
                  <td className="py-2 px-4 border-b border-gray-300">
                    {utilities.currencyFormatter(parseFloat(item.cost), "")}
                  </td>
                  <td className="py-2 px-4 border-b border-gray-300">
                    {item.qty}
                  </td>
                </tr>
              )
            )}
          </tbody>
        </table>
        {status?.toLowerCase() === "ready" ? (
          <div className="flex item-center justify-end gap-4 w-full">
            <div className="mt-8">
              <Button
                variant="default"
                color="success"
                onClick={() => setIsProceed(true)}
              >
                Proceed
              </Button>
            </div>
            <div className="mt-8">
              <Button
                variant="destructive"
                className=""
                onClick={() => setIsOpen(true)}
              >
                Dismiss Quotation
              </Button>
            </div>
          </div>
        ) : (
          <>
            <p className="text-center italic text-sm mt-5 text-red-500">
              This Quotation is no longer valid, please make a new order
            </p>
            <div className="flex item-center justify-end gap-4 w-full">
              <div className="mt-8">
                <Button asChild variant="default" color="success">
                  <Link to={"/purchasing-orders"}>Place New Order</Link>
                </Button>
              </div>
              <div className="mt-8">
                <Button
                  variant="destructive"
                  className=""
                  onClick={() => setIsOpen(true)}
                >
                  Dismiss Quotation
                </Button>
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default QuotationDetails;
