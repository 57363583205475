import DashboardLayout from "../view/home/dashboard";
import navigationRoutes from "../../../routes/navigationRoutes";
import Tutorials from "../view/tutorials";
import PrivilegeProtectedRoute from "../../../routes/privilegeProtectedRoute";
import authService from "../../../services/authService";
import ActivityFeed from "../view/activities/activityFeed";
import ActiveSchedules from "../view/active/activeHospitals";
import Active from "../view/active/Active";
import Events from "../view/event/events";
import MaintenanceSchedule from "../view/MaintenanceSchedule";
import CreateEvents from "../view/event/CreateEvents";
import Appointments from "../view/appointments";
import Requests from "../view/requests";
import Tools from "../view/tool/tools";
import Story from "../view/hospitalStories/story";
import Dazzle from "../view/dazzle/dazzle";
import Drips from "../view/drips/drips";
import Facility from "../view/facilities/facility";
import Support from "../view/support";
import Home from "../view/home/home";
import Products from "../view/products";
import CancelledOrders from "../view/cancelledOrders";
import ReviewActiveSchedule from "../view/exodus/ReviewActiveSchedule";
import PitchForm from "../view/pipeline/pitch/pitchForm";
import Prospects from "../view/prospects";
import ComplaintsPage from "../view/complaints";
import AllExodusComplain from "../view/exodus/AllExodusComplain";
import ContactLog from "../view/exodus/ContactLog";
import Exodus from "../view/exodus/Exodus";
import ExodusActivities from "../view/exodus/ExodusActivities";
import ExodusCall from "../view/exodus/ExodusCall";
import LogAContact from "../view/exodus/LogAContact";
import SendADrip from "../view/exodus/SendADrip";
import SendANote from "../view/exodus/SendANote";
import SendDrip from "../view/exodus/SendDrip";
import SingleExodusHospital from "../view/exodus/SingleExodusHospital";
import SingleExodusOrder from "../view/exodus/SingleExodusOrder";
import Leads from "../view/agent/leads";
import NerveClaims from "../view/nerve/nerveClaims";
import OnboardChecklist from "../view/pipeline/onboard/onboardChecklist";
import Pipeline from "../view/pipeline/pipelineDetails";
import PreOnboardInfo from "../view/preOnboardInfo";
import RequestInfo from "../view/pipeline/close/requestInfo";
import ScoreCard from "../view/reward/scoreCard";
import MessageLeads from "../view/MessageLeadPage";
import PurchasingOrder from "../view/purchasingOrder/PurchasingOrder";
import LeadsMessages from "../view/leadMessages";
import Hospitals from "../view/hospitals/hospitals";
import SingleHospital from "../view/hospitals/singleHospital";
import ContactHospital from "../view/hospitals/contactHospital";
import HospitalCall from "../view/hospitals/logHospitalCall";
import ExodusList from "../view/exodus/ExodusList";
import OrderCategory from "../view/purchasingOrder/OrderCategories";
import Order from "../view/purchasingOrder/Order";
import PurchaseItem from "../view/purchasingOrder/PurchaseItem";
import PurchasingCart from "../view/purchasingOrder/OrderCart";
import BuyEquipments from "../view/purchasingOrder/BuyEquipments";
import { EquipmentList } from "../view/purchasingOrder/EquipmentList";

import ExodusPipeline from "../view/exodus/ExodusPipeline";
import CadreRequest from "../view/cadre/CadreRequest";
import HospitalActivities from "../view/hospitals/HospitalActivities";

import EquipmentDetails from "../view/purchasingOrder/EquipmentDetails";
import NerveUsage from "../view/nerve/nerveusage";
import TrackingPage from "../view/purchasingOrder/Tracking";
import Quotation from "../view/quotations/Quotation";
import LogisticTracker from "../view/logisticTracker/logistic";
import QuotationDetails from "../view/quotations/QuotationDetails";
import OrderInformation from "../view/quotations/PlaceOrder";
import SetTarget from "../view/targets/SetTarget";
import HospitalHistory from "../view/hospitals/History";
import FullChurn from "../view/churn/FullChurn";
import Churn from "../view/churn/Churn";
import ReservedList from "../view/hospitals/ReservedList";
import HealthScore from "../view/health-score/HealthScore";

const privileges = authService.getPrivileges();
const routes = [
  {
    path: navigationRoutes.home,
    element: (
      <PrivilegeProtectedRoute
        privilege={privileges.viewDashboard}
        element={<DashboardLayout />}
      />
    ),
    children: [
      {
        path: "", // Default route, corresponds to /dashboard
        element: <Home />,
      },
    ],
  },

  {
    path: "/documents",
    element: (
      <PrivilegeProtectedRoute
        privilege={privileges.viewPipelines}
        element={<DashboardLayout />}
      />
    ),
    children: [
      {
        path: "",
        element: <Support />,
      },
    ],
  },
  {
    path: "/tutorials",
    element: (
      <PrivilegeProtectedRoute
        privilege={privileges.viewPipelines}
        element={<DashboardLayout />}
      />
    ),
    children: [
      {
        path: "",
        element: <Tutorials />,
      },
    ],
  },

  {
    path: "/hospitals",
    element: (
      <PrivilegeProtectedRoute
        privilege={privileges.viewHospitals}
        element={<DashboardLayout />}
      />
    ),
    children: [
      {
        path: "",
        element: <Hospitals />,
      },
      {
        path: "/hospitals/:id",
        element: <SingleHospital />,
      },
      {
        path: "/hospitals/:id/*",
        element: <ContactHospital />,
      },
      {
        path: "/hospitals/:id/history",
        element: <HospitalHistory />,
      },
      {
        path: "/hospitals/log/call",
        element: <HospitalCall />,
      },
      {
        path: "/hospitals/activities",
        element: <HospitalActivities />,
      },
    ],
  },
  // history route
  {
    path: "/active-feed",
    element: (
      <PrivilegeProtectedRoute
        privilege={privileges.viewActivityFeed}
        element={<DashboardLayout />}
      />
    ),
    children: [
      {
        path: "",
        element: <ActivityFeed />,
      },
    ],
  },
  {
    path: "/nerve-usage",
    element: (
      <PrivilegeProtectedRoute
        privilege={privileges.nerveUsage}
        element={<DashboardLayout />}
      />
    ),
    children: [
      {
        path: "",
        element: <NerveUsage />,
      },
    ],
  },
  {
    path: "/active-hospitals",
    element: (
      <PrivilegeProtectedRoute
        privilege={privileges.activeHospitals}
        element={<DashboardLayout />}
      />
    ),
    children: [
      {
        path: "",
        element: <ActiveSchedules />,
      },
      {
        path: "/active-hospitals/:id",
        element: <SingleHospital />,
      },
      // {
      //   path: "/active-hospitals/:id/*",
      //   element: <ContactHospital />,
      // },
      // {
      //   path: "/active-hospitals/log/call",
      //   element: <HospitalCall />,
      // },
    ],
  },
  // customer success
  {
    path: "/active-schedules",
    element: (
      <PrivilegeProtectedRoute
        privilege={privileges.assignActive}
        element={<DashboardLayout />}
      />
    ),
    children: [
      {
        path: "",
        element: <Active />,
      },
    ],
  },
  {
    path: "/maintenance",
    element: (
      <PrivilegeProtectedRoute
        privilege={privileges.maintenance}
        element={<DashboardLayout />}
      />
    ),
    children: [
      {
        path: "",
        element: <MaintenanceSchedule />,
      },
      {
        path: "/maintenance/:id",
        element: <SingleHospital />,
      },
    ],
  },
  {
    path: "/events",
    element: (
      <PrivilegeProtectedRoute
        privilege={privileges.events}
        element={<DashboardLayout />}
      />
    ),
    children: [
      {
        path: "",
        element: <Events />,
      },
      {
        path: "create-event",
        element: <CreateEvents />,
      },
    ],
  },
  {
    path: "/appointments",
    element: (
      <PrivilegeProtectedRoute
        privilege={privileges.viewAppointments}
        element={<DashboardLayout />}
      />
    ),
    children: [
      {
        path: "",
        element: <Appointments />,
      },
    ],
  },
  {
    path: "/requests",
    element: (
      <PrivilegeProtectedRoute
        privilege={privileges.viewRequests}
        element={<DashboardLayout />}
      />
    ),
    children: [
      {
        path: "",
        element: <Requests />,
      },
    ],
  },
  {
    path: "/tool",
    element: (
      <PrivilegeProtectedRoute
        privilege={privileges.tools}
        element={<DashboardLayout />}
      />
    ),
    children: [
      {
        path: "",
        element: <Tools />,
      },
    ],
  },
  {
    path: "/story",
    element: (
      <PrivilegeProtectedRoute
        privilege={privileges.story}
        element={<DashboardLayout />}
      />
    ),
    children: [
      {
        path: "",
        element: <Story />,
      },
    ],
  },
  {
    path: "/dazzles",
    element: (
      <PrivilegeProtectedRoute
        privilege={privileges.dazzle}
        element={<DashboardLayout />}
      />
    ),
    children: [
      {
        path: "",
        element: <Dazzle />,
      },
    ],
  },

  {
    path: "/drip",
    element: (
      <PrivilegeProtectedRoute
        privilege={privileges.viewDrip}
        element={<DashboardLayout />}
      />
    ),
    children: [
      {
        path: "",
        element: <Drips />,
      },
    ],
  },
  {
    path: "/facility",
    element: (
      <PrivilegeProtectedRoute
        privilege={privileges.viewFacilities}
        element={<DashboardLayout />}
      />
    ),
    children: [
      {
        path: "",
        element: <Facility />,
      },
    ],
  },
  {
    path: "/products",
    element: (
      <PrivilegeProtectedRoute
        privilege={privileges.viewProductTypes}
        element={<DashboardLayout />}
      />
    ),
    children: [
      {
        path: "",
        element: <Products />,
      },
    ],
  },

  {
    path: "/order/track/:user_id/:order_id/:order_type",
    element: (
      <PrivilegeProtectedRoute
        privilege={privileges.purchasingOrder}
        element={<DashboardLayout />}
      />
    ),
    children: [
      {
        path: "",
        element: <TrackingPage />,
      },
    ],
  },
  {
    path: "/purchasing-orders",
    element: (
      <PrivilegeProtectedRoute
        privilege={privileges.purchasingOrder}
        element={<DashboardLayout />}
      />
    ),
    children: [
      {
        path: "",
        element: <PurchasingOrder />,
        children: [
          {
            path: "",
            element: <Order />,
          },
          {
            path: "/purchasing-orders/category",
            element: <OrderCategory />,
          },
          {
            path: "/purchasing-orders/category/:item",
            element: <PurchaseItem />,
          },
          {
            path: "/purchasing-orders/category/buy-equipment",
            element: <BuyEquipments />,
          },
          {
            path: "/purchasing-orders/category/buy-equipment/:item",
            element: <EquipmentList />,
          },
          {
            path: "/purchasing-orders/category/buy-equipment/:item/:id",
            element: <EquipmentDetails />,
          },
          {
            path: "/purchasing-orders/cart",
            element: <PurchasingCart />,
          },
        ],
      },
    ],
  },
  {
    path: "/quotations",
    element: (
      <PrivilegeProtectedRoute
        privilege={privileges.purchasingOrder}
        element={<DashboardLayout />}
      />
    ),
    children: [
      {
        path: "",
        element: <Quotation />,
        children: [],
      },
      {
        path: "/quotations/details/:id/:status",
        element: <QuotationDetails />,
      },
      {
        path: "/quotations/order-information",
        element: <OrderInformation />,
      },
    ],
  },
  {
    path: "/logistic-tracker",
    element: (
      <PrivilegeProtectedRoute
        privilege={privileges.viewHospitals}
        element={<DashboardLayout />}
      />
    ),
    children: [
      {
        path: "",
        element: <LogisticTracker />,
      },
    ],
  },
  {
    path: "/cancelled-orders",
    element: (
      <PrivilegeProtectedRoute
        privilege={privileges.myHospitals}
        element={<DashboardLayout />}
      />
    ),
    children: [
      {
        path: "",
        element: <CancelledOrders />,
      },
    ],
  },
  {
    path: "/message-lead/:hospitalId/:hospitalname",
    element: (
      <PrivilegeProtectedRoute
        privilege={privileges.pitch}
        element={<DashboardLayout />}
      />
    ),
    children: [
      {
        path: "",
        element: <MessageLeads />,
      },
    ],
  },
  {
    path: "/info",
    element: (
      <PrivilegeProtectedRoute
        privilege={privileges.pitch}
        element={<DashboardLayout />}
      />
    ),
    children: [
      {
        path: "",
        element: <PitchForm navigation={undefined} />,
      },
    ],
  },
  {
    path: "/request-info",
    element: (
      <PrivilegeProtectedRoute
        privilege={privileges.viewRequests}
        element={<DashboardLayout />}
      />
    ),
    children: [
      {
        path: "",
        element: <RequestInfo />,
      },
    ],
  },
  {
    path: "/onboard-info",
    element: (
      <PrivilegeProtectedRoute
        privilege={privileges.viewRequests}
        element={<DashboardLayout />}
      />
    ),
    children: [
      {
        path: "",
        element: <PreOnboardInfo />,
      },
    ],
  },
  // {
  //   path: "/health-score",
  //   element: (
  //     <PrivilegeProtectedRoute
  //       privilege={privileges.viewHospitalScore}
  //       element={<DashboardLayout />}
  //     />
  //   ),
  //   children: [
  //     {
  //       path: "",
  //       element: <hospitalScoreCard />,
  //     },
  //   ],
  // },
  {
    path: "/prospects",
    element: (
      <PrivilegeProtectedRoute
        privilege={privileges.viewProspectsPage}
        element={<DashboardLayout />}
      />
    ),
    children: [
      {
        path: "",
        element: <Prospects />,
      },
      {
        path: "/prospects/:id",
        element: <SingleHospital />,
      },
    ],
  },
  {
    path: "/nerve-claims",
    element: (
      <PrivilegeProtectedRoute
        privilege={privileges.nerveClaims}
        element={<DashboardLayout />}
      />
    ),
    children: [
      {
        path: "",
        element: <NerveClaims />,
      },
    ],
  },
  {
    path: "/mymessages",
    element: (
      <PrivilegeProtectedRoute
        privilege={privileges.viewMessages}
        element={<DashboardLayout />}
      />
    ),
    children: [
      {
        path: "",
        element: <LeadsMessages />,
      },
    ],
  },
  {
    path: "/complaints",
    element: (
      <PrivilegeProtectedRoute
        privilege={privileges.viewComplaints}
        element={<DashboardLayout />}
      />
    ),
    children: [
      {
        path: "",
        element: <ComplaintsPage />,
      },
    ],
  },
  // {
  //   path: "/onboard",
  //   element: (
  //     <PrivilegeProtectedRoute
  //       privilege={privileges.viewOnboard}
  //       element={<DashboardLayout />}
  //     />
  //   ),
  //   children: [
  //     {
  //       path: "",
  //       element: <Onboard />,
  //     },
  //   ],
  // },
  {
    path: "/checklist/:id",
    element: (
      <PrivilegeProtectedRoute
        privilege={privileges.viewOnboard}
        element={<DashboardLayout />}
      />
    ),
    children: [
      {
        path: "",
        element: <OnboardChecklist />,
      },
    ],
  },
  {
    path: "/pipeline",
    element: (
      <PrivilegeProtectedRoute
        privilege={privileges.viewPipelines}
        element={<DashboardLayout />}
      />
    ),
    children: [
      {
        path: "",
        element: <Pipeline />,
      },
    ],
  },
  {
    path: "/leads",
    element: (
      <PrivilegeProtectedRoute
        privilege={privileges.viewLeadArena}
        element={<DashboardLayout />}
      />
    ),
    children: [
      {
        path: "",
        element: <Leads />,
      },
      {
        path: "/leads/my-score/:agentId/:agentUsername",
        element: <ScoreCard />,
      },
    ],
  },
  {
    path: "/my-score/:agentId/:agentUsername",
    element: (
      <PrivilegeProtectedRoute
        privilege={privileges.viewAllScoresPage}
        element={<DashboardLayout />}
      />
    ),
    children: [
      {
        path: "",
        element: <ScoreCard />,
      },
    ],
  },
  {
    path: "/exodus",
    element: (
      <PrivilegeProtectedRoute
        privilege={privileges.exodus}
        element={<DashboardLayout />}
      />
    ),
    children: [
      {
        path: "",
        element: <Exodus />,
      },
      {
        path: "/exodus/list",
        element: <ExodusList />,
      },
      {
        path: "/exodus/list/:id",
        element: <SingleExodusHospital />,
      },
      {
        path: "/exodus/activities",
        element: <ExodusActivities />,
      },
      {
        path: "/exodus/complains",
        element: <AllExodusComplain />,
      },
      {
        path: "/exodus/log-a-contact",
        element: <LogAContact />,
      },
      {
        path: "/exodus/log-a-contact/contact",
        element: <ContactLog />,
      },
      {
        path: "/exodus/call",
        element: <ExodusCall />,
      },
      {
        path: "/exodus/send-a-drip",
        element: <SendADrip />,
      },
      {
        path: "/exodus/send-a-note",
        element: <SendANote />,
      },
      {
        path: "/exodus/:id",
        element: <ExodusPipeline />,
      },
    ],
  },

  {
    path: "/hospitals/exodus-send-drip",
    element: (
      <PrivilegeProtectedRoute
        privilege={privileges.exodusSendDrip}
        element={<DashboardLayout />}
      />
    ),
    children: [
      {
        path: "",
        element: <SendDrip />,
      },
    ],
  },

  {
    path: "/hospitals/single-exodus/order",
    element: (
      <PrivilegeProtectedRoute
        privilege={privileges.singleExodusOrder}
        element={<DashboardLayout />}
      />
    ),
    children: [
      {
        path: "",
        element: <SingleExodusOrder />,
      },
    ],
  },
  {
    path: "/hospital-review-active-schedule",
    element: (
      <PrivilegeProtectedRoute
        privilege={privileges.reviewActiveSchedule}
        element={<DashboardLayout />}
      />
    ),
    children: [
      {
        path: "",
        element: <ReviewActiveSchedule />,
      },
    ],
  },
  {
    path: "cadre-request",
    element: (
      <PrivilegeProtectedRoute
        privilege={privileges.viewLeadArena}
        element={<DashboardLayout />}
      />
    ),
    children: [
      {
        path: "",
        element: <CadreRequest />,
      },
    ],
  },
  {
    path: "set-target",
    element: (
      <PrivilegeProtectedRoute
        privilege={privileges.createAgent}
        element={<DashboardLayout />}
      />
    ),
    children: [
      {
        path: "",
        element: <SetTarget />,
      },
    ],
  },

  {
    path: "/churn",
    element: (
      <PrivilegeProtectedRoute
        privilege={privileges.viewComplaints}
        element={<DashboardLayout />}
      />
    ),
    children: [
      {
        path: "",
        element: <Churn />,
      },
    ],
  },
  {
    path: "/full-churn",
    element: (
      <PrivilegeProtectedRoute
        privilege={privileges.viewComplaints}
        element={<DashboardLayout />}
      />
    ),
    children: [
      {
        path: "",
        element: <FullChurn />,
      },
    ],
  },
  {
    path: "reserved",
    element: (
      <PrivilegeProtectedRoute
        privilege={privileges.createAgent}
        element={<DashboardLayout />}
      />
    ),
    children: [
      {
        path: "",
        element: <ReservedList />,
      },
    ],
  },
  {
    path: "health-score",
    element: (
      <PrivilegeProtectedRoute
        privilege={privileges.exodus}
        element={<DashboardLayout />}
      />
    ),
    children: [
      {
        path: "",
        element: <HealthScore />,
      },
    ],
  },
];

export default routes;
