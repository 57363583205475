import useDateString, { DateFormat } from "../hooks/dates/useDateString";

type DateValue = string | number;

// Helper function to parse different date formats
const parseDateValue = (value: DateValue): Date | null => {
  if (!value) return null;

  // If it's a number (unix timestamp), multiply by 1000 for milliseconds
  if (typeof value === "number" || !isNaN(Number(value))) {
    const timestamp = Number(value) * 1000; // Convert to milliseconds
    return new Date(timestamp);
  }

  // Handle string value
  if (typeof value === "string") {
    // Check if it matches the format "YYYY-MM-DD HH:mm:ssam/pm"
    const customFormatRegex =
      /^(\d{4})-(\d{2})-(\d{2})\s(\d{2}):(\d{2}):(\d{2})(am|pm)$/i;
    const match = value.match(customFormatRegex);

    if (match) {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const [_, year, month, day, hours, minutes, seconds, period] = match;
      let hour = parseInt(hours);

      // Convert to 24-hour format
      if (period.toLowerCase() === "pm" && hour < 12) {
        hour += 12;
      } else if (period.toLowerCase() === "am" && hour === 12) {
        hour = 0;
      }

      // Create date object (month is 0-based in JavaScript)
      const date = new Date(
        parseInt(year),
        parseInt(month) - 1,
        parseInt(day),
        hour,
        parseInt(minutes),
        parseInt(seconds)
      );

      if (!isNaN(date.getTime())) {
        return date;
      }
    }

    // If it doesn't match the custom format, try parsing as a regular date string
    const date = new Date(value);
    if (!isNaN(date.getTime())) {
      return date;
    }
  }

  return null;
};

const DateCell = ({
  dateString,
  format,
}: {
  dateString: DateValue;
  format: DateFormat;
}) => {
  const date = parseDateValue(dateString);
  const formattedDate = useDateString(date || new Date(), format);

  return <span>{date && !isNaN(date.getTime()) ? formattedDate : "N/A"}</span>;
};

export default DateCell;
